import { createSlice } from '@reduxjs/toolkit'

export const dialogSlice = createSlice({
    name: 'openDialog',
    initialState: {
        shareLabels: {
            value: false,
        },
        addNewContact: {
            value: false,
        },
        addExistingContacts: {
            value: false,
        },
        addContactsToLabels: {
            value: false,
        },
        addNewLabel: {
            value: false,
        },
        addUserToSubscription: {
            value: false,
        },
        renameLabel: {
            value: false,
        },
        alertDeleteLabel: {
            value: false,
        },
        alertRemoveContactFromLabel: {
            value: false,
        },
        alertDeleteContact: {
            value: false,
        },
        editContact: {
            value: false,
        },
        changeBillingEmail: {
            value: false,
        },
        helpCrunchChat: {
            value: false,
        },
        alertDeleteLicense: {
            value: false,
        },
        reviewAndCheckout: {
            value: false,
        },
        switchToAnnual: {
            value: false,
        },
        warningShareAllContacts: {
            value: false,
        },
        meeting: {
            value: false,
        },
        welcome: {
            value: false,
        },
        invitationPending: {
            value: false,
        },
        revokedCollaborator: {
            value: false,
        },
        alertAddUserToSubscription: {
            value: false,
        },
        deleteUserFromManageUsers: {
            value: false,
        },
        removeAdminPermissions: {
            value: false,
        },
        addAdminPermissions: {
            value: false,
        },
    },
    reducers: {
        openShareLabelsDialog: (state, action) => {
            state.shareLabels.value = action.payload;
        },
        openAddContactDialog: (state, action) => {
            state.addNewContact.value = action.payload;
        },
        openAddExistingContactsDialog: (state, action) => {
            state.addExistingContacts.value = action.payload;
        },
        openAddContactToLabelsDialog: (state, action) => {
            state.addContactsToLabels.value = action.payload;
        },
        openAddNewLabelDialog: (state, action) => {
            state.addNewLabel.value = action.payload;
        },
        openAddUserToSubscriptionDialog: (state, action) => {
            state.addUserToSubscription.value = action.payload;
        },
        openRenameLabelDialog: (state, action) => {
            state.renameLabel.value = action.payload;
        },
        openAlertDeleteLabelDialog: (state, action) => {
            state.alertDeleteLabel.value = action.payload;
        },
        openAlertRemoveContactFromLabelDialog: (state, action) => {
            state.alertRemoveContactFromLabel.value = action.payload;
        },
        openAlertDeleteContactDialog: (state, action) => {
            state.alertDeleteContact.value = action.payload;
        },
        openEditContactDialog: (state, action) => {
            state.editContact.value = action.payload;
        },
        openChangeBillingEmailDialog: (state, action) => {
            state.changeBillingEmail.value = action.payload;
        },
        openHelpCrunchChat: (state, action) => {
            state.helpCrunchChat.value = action.payload;
        },
        openAlertDeleteLicenseDialog: (state, action) => {
            state.alertDeleteLicense.value = action.payload;
        },
        openReviewAndCheckoutDialog: (state, action) => {
            state.reviewAndCheckout.value = action.payload;
        },
        openSwitchToAnnualDialog: (state, action) => {
            state.switchToAnnual.value = action.payload;
        },
        openWarningShareAllContactsDialog: (state, action) => {
            state.warningShareAllContacts.value = action.payload;
        },
        openMeetingDialog: (state, action) => {
            state.meeting.value = action.payload;
        },
        openWelcomeDialog: (state, action) => {
            state.welcome.value = action.payload;
        },
        openInvitationPending: (state, action) => {
            state.invitationPending.value = action.payload;
        },
        openRevokedCollaborator: (state, action) => {
            state.revokedCollaborator.value = action.payload;
        },
        openAlertAddUserToSubscriptionDialog: (state, action) => {
            state.alertAddUserToSubscription.value = action.payload;
        },
        openDeleteUserFromManageUsersDialog: (state, action) => {
            state.deleteUserFromManageUsers.value = action.payload;
        },
        openRemoveAdminPermissionsDialog: (state, action) => {
            state.removeAdminPermissions.value = action.payload;
        },
        openAddAdminPermissionsDialog: (state, action) => {
            state.addAdminPermissions.value = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    openShareLabelsDialog,
    openAddContactDialog,
    openAddContactToLabelsDialog,
    openAddExistingContactsDialog,
    openAddNewLabelDialog,
    openAddUserToSubscriptionDialog,
    openRenameLabelDialog,
    openAlertDeleteLabelDialog,
    openAlertRemoveContactFromLabelDialog,
    openAlertDeleteContactDialog,
    openEditContactDialog,
    openChangeBillingEmailDialog,
    openHelpCrunchChat,
    openAlertDeleteLicenseDialog,
    openReviewAndCheckoutDialog,
    openSwitchToAnnualDialog,
    openWarningShareAllContactsDialog,
    openMeetingDialog,
    openWelcomeDialog,
    openInvitationPending,
    openRevokedCollaborator,
    openAlertAddUserToSubscriptionDialog,
    openDeleteUserFromManageUsersDialog,
    openRemoveAdminPermissionsDialog,
    openAddAdminPermissionsDialog,
} = dialogSlice.actions

export default dialogSlice.reducer
