import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from "react-redux";
import { Grid, IconButton, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";

// project import
import store from "../../store/store";
import { openAddAdminPermissionsDialog } from "../../store/reducers/dialogSlice";
import {
    subscriptionSetAdmin,
    getSubscriptionData,
    setUsersToAddAdminPermissions,
    setSelectedUsers,
} from "../../store/reducers/userSlice";
import { showNotification } from "../../store/reducers/notificationSlice";

export default function AlertAddAdminPermissionsDialog() {
    const open = useSelector((state) => state.dialogsOpen.addAdminPermissions.value);
    const usersToAddAdminPermissions = useSelector((state) => state.user.usersToAddAdminPermissions);
    const subscriptionSetAdminStatus = useSelector((state) => state.user.subscriptionSetAdminStatus);
    if (!open) {
        return null;
    }
    const loading = subscriptionSetAdminStatus === 'loading';
    const handleClose = () => {
        store.dispatch(openAddAdminPermissionsDialog(false));
    };
    const handleAddUsers = async () => {
        const users = {
            setAdmin: true,
            emails: usersToAddAdminPermissions.map(user => user.email),
        }
        await store.dispatch(subscriptionSetAdmin(users));
        store.dispatch(setUsersToAddAdminPermissions([]));
        store.dispatch(setSelectedUsers([]));
        handleClose();
        await store.dispatch(getSubscriptionData());
    };
    const message = `You are about to grant admin access to the selected user${usersToAddAdminPermissions?.length > 1 ? 's' : ''}. This will allow them to manage user licenses and the subscription.`;
    const CancelButton = () => {
        return (
            <Button onClick={handleClose}>
                <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Cancel</Typography>
            </Button>
        );
    };
    const ConfirmButton = () => {
        return (
            <LoadingButton
                loading={loading}
                disabled={loading}
                onClick={handleAddUsers}
            >
                <Typography variant={'dialogButton'} sx={{
                    color: loading ? 'rgba(0, 0, 0, 0.32)' : 'rgba(59, 125, 237, 1)'
                }}>Confirm</Typography>
            </LoadingButton>
        );
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-delete-licenses-dialog-title"
            aria-describedby="alert-delete-licenses-dialog-description"
            PaperProps={{
                sx: {
                    minWidth: 444,
                    backgroundColor: 'rgba(59, 125, 237, 1)'
                }
            }}
        >
            <DialogTitle sx={{ backgroundColor: 'rgba(59, 125, 237, 1)' }}>
                <Grid container sx={{ alignItems: 'center' }}>
                    <Grid item>
                        <Typography
                            variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Warning</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: 'rgba(255, 255, 255, 0.54)',
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        backgroundColor: 'rgba(0, 0, 0, 0.16)'
                    },
                }}
            >
                <CloseOutlined />
            </IconButton>
            <DialogContent dividers sx={{ border: 0, backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                <Typography
                    sx={{
                        color: 'rgba(0, 0, 0, 0.87)',
                        fontSize: 16,
                        fontFamily: `"Roboto"`,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.2px',
                    }}
                >
                    {message}
                </Typography>
            </DialogContent>
            <DialogActions sx={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                <CancelButton />
                <ConfirmButton />
            </DialogActions>
        </Dialog>
    );
}

