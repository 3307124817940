import { SvgIcon } from '@mui/material';

export const LicensedUsersIcon = () => {
    return <SvgIcon sx={{ width: 22, height: 22 }}>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.584 11.9167V16.4725L11.0007 14.5108L6.41732 16.4725V4.58333H9.62476V2.75H6.41732C5.40898 2.75 4.58398 3.575 4.58398 4.58333V19.25L11.0007 16.5L17.4173 19.25V11.9167H15.584Z" fill="black" fillOpacity="0.54" />
            <path d="M15.4275 10.0827L11 5.65516L12.2925 4.36266L15.4183 7.48849L20.4967 2.41016L21.7892 3.70266L15.4275 10.0827Z" fill="#2E7D32" />
        </svg>
    </SvgIcon>
}

export const NoLicensedUsersIcon = () => {
    return <SvgIcon sx={{ width: 22, height: 22 }}>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.584 8.70833V16.4725L11.0007 14.5108L6.41732 16.4725V4.58333H9.62565V2.75H6.41732C5.40898 2.75 4.58398 3.575 4.58398 4.58333V19.25L11.0007 16.5L17.4173 19.25V8.70833H15.584Z" fill="black" fillOpacity="0.54" />
            <path d="M21.0837 6.41536H11.917V4.58203H21.0837V6.41536Z" fill="#D32F2F" />
        </svg>
    </SvgIcon>
}