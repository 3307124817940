import * as React from "react";
import {
    AppBar,
    Box,
    Button,
    Toolbar,
    Tooltip,
    Typography,
    IconButton,
    Stack,
    SvgIcon
} from "@mui/material";
import {
    SyncOutlined,
    SupervisedUserCircleOutlined,
    MonetizationOnOutlined,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { keyframes } from "@emotion/react";
import { styled } from "@mui/material/styles";
import { useNavigate, Link as RouterLink } from 'react-router-dom';

// project import
import ProfileMenu from "./ProfileMenu";
import { openHelpCrunchChat } from "../../store/reducers/dialogSlice";
import store from "../../store/store";
import { setSyncStatus, syncUserGroups } from "../../store/reducers/userSlice";
import { HEADER_HEIGHT } from "../../constants/size";
import SearchContactsVirtualized from "./SearchContactsVirtualized";

const myKeyframe = keyframes`
     0% {
        transform: rotate(0deg);
     }
     100% {    
        transform: rotate(360deg);
     }
`;
const SyncOutlinedAnimated = styled(SyncOutlined)(() => ({
    animation: `${myKeyframe} 2s infinite linear`,
    transform: 'translateZ(0)',
    transformOrigin: '50% 50%',
}));
export default function Header() {
    const status = useSelector((state) => state.data.status);
    const syncStatus = useSelector((state) => state.user.syncStatus);
    const helpCrunchChatStatus = useSelector((state) => state.dialogsOpen.helpCrunchChat.value);
    const subscription = useSelector((state) => state.user.subscription);
    const handleClickOpenHelpCrunchChat = async () => {
        window.HelpCrunch('onReady', () => {
            if (helpCrunchChatStatus) {
                store.dispatch(openHelpCrunchChat(false));
                window.HelpCrunch('closeChat');
            } else {
                store.dispatch(openHelpCrunchChat(true));
                window.HelpCrunch('openChat');
            }
        });
    };
    const navigate = useNavigate();
    return (
        <AppBar
            position="fixed"
            elevation={0}
            sx={{
                height: `${HEADER_HEIGHT}px`,
                zIndex: (theme) => theme.zIndex.drawer + 1,
                background: '#FFF',
            }}>
            <Toolbar disableGutters sx={{ alignItems: "center", justifyContent: "center", minHeight: `${HEADER_HEIGHT}px` }}>
                <RouterLink to={process.env.REACT_APP_PUBLIC_URL} style={{ textDecoration: 'none' }}>
                    <Stack ml={1} direction={'row'} spacing={0}
                        sx={{ width: 278, height: 64, display: 'flex', alignItems: 'center' }}>
                        <Box
                            component="img"
                            sx={{
                                width: 40,
                                height: 40,
                            }}
                            alt="Logo"
                            src={`${process.env.REACT_APP_PUBLIC_URL}assets/logo.png`}
                            srcSet={`${process.env.REACT_APP_PUBLIC_URL}assets/logo2x.png 2x`}
                            ml={1}
                        />
                        <Box
                            ml={'2px'}
                            sx={{
                                minWidth: 209,
                                maxWidth: 209,
                            }}>
                            <Typography variant={'subtitle2'} color={'#5F6368'}>
                                Shared Contacts Manager
                            </Typography>
                        </Box>
                    </Stack>
                </RouterLink>
                <SearchContactsVirtualized />
                <Box sx={{ flexGrow: 1, marginLeft: '26px', marginRight: 2 }} />
                <Box mr={'20px'} sx={{ display: { xs: 'flex', md: 'flex' }, alignItems: 'center' }}>
                    {renderSyncButton(subscription, syncStatus, status)}
                    {renderManageUsersButton(navigate, subscription)}
                    {renderManageSubscriptionButton(navigate, subscription)}
                    <Tooltip title='Get support'>
                        <IconButton
                            aria-label='helpcrunch_chat'
                            color='default'
                            size="medium"
                            onClick={handleClickOpenHelpCrunchChat}
                            sx={{ marginLeft: '4px' }}
                        >
                            <SvgIcon>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11 18H13V16H11V18ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12 6C9.79 6 8 7.79 8 10H10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 12 11 11.75 11 15H13C13 12.75 16 12.5 16 10C16 7.79 14.21 6 12 6Z"
                                        fill="black" fillOpacity="0.54" />
                                </svg>
                            </SvgIcon>
                            {/* <ContactSupportOutlined sx={{width: 24, height: 24}}/> */}
                        </IconButton>
                    </Tooltip>
                    <Box ml={2}>
                        <ProfileMenu />
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>

    )
}

const renderSyncButton = (subscription, syncStatus, status) => {
    if (Object.keys(subscription).length === 0) return null;
    const handleClickSyncContacts = async () => {
        store.dispatch(syncUserGroups());
        store.dispatch(setSyncStatus('syncing'))
    };
    if (subscription.plan_id === 1 && subscription.status === 0) return null;
    return (
        <Tooltip
            title='Start the syncing process manually. You can close the app during the sync process. Tip: The sync starts automatically every 3 hours.'>
            <Box mr={2}>
                <Button
                    sx={{
                        width: 175
                    }}
                    onClick={handleClickSyncContacts}
                    variant="outlined"
                    color={'buttonOutlined'}
                    startIcon={
                        syncStatus === 'syncing' ?
                            <SyncOutlinedAnimated />
                            :
                            <SyncOutlined />
                    }
                >
                    <Typography variant={'button'} color={'#202124'}>
                        {status === 'syncing' ? 'Syncing' : 'Sync contacts'}
                    </Typography>
                </Button>
            </Box>
        </Tooltip>
    )
}

const renderManageUsersButton = (navigate, subscription) => {
    if (Object.keys(subscription).length === 0) return null;
    return (
        <Tooltip title='Manage Users'>
            <IconButton
                aria-label='manage_users'
                color='default'
                size="medium"
                onClick={() => navigate("/manage_users")}
            >
                <SupervisedUserCircleOutlined />
            </IconButton>
        </Tooltip>
    )
}

const renderManageSubscriptionButton = (navigate, subscription) => {
    if (Object.keys(subscription).length === 0) return null;
    return (
        <Tooltip title='Manage Subscription'>
            <IconButton
                aria-label='manage_subscription'
                color='default'
                size="medium"
                onClick={() => navigate("/manage_subscription")}
                sx={{ marginLeft: '4px' }}
            >
                <MonetizationOnOutlined />
            </IconButton>
        </Tooltip>
    )
}
