import {
    getSubscriptionData,
    updateShareableUsers,
    updateUser,
    getAmount, setPricePerUser, setCounter, setSubscriptionType, setCurrency,
    getUser, getDomainInformation,
} from "../store/reducers/userSlice";
import {getContactsAndLabels, updateStatusFirstLoad} from "../store/reducers/contactsSlice";
import {setDomainData, updateGroups} from "../store/reducers/groupsSlice";
import {setGA4ClientId} from "../store/reducers/dataSlice";
import {usersCountToPlanCount} from "../services/usersCountToPlanCount";
import store from "../store/store";
import {openHelpCrunchChat} from "../store/reducers/dialogSlice";

export default async function fetchAllData(dispatch) {
    const [getUserResult, subscriptionResult, contactsResult, domainInfoResult] = await Promise.all([
        dispatch(getUser()),
        dispatch(getSubscriptionData()),
        dispatch(getContactsAndLabels()),
        dispatch(getDomainInformation()),
    ]);
    const user = getUserResult.payload.me;
    await processSubscriptionData(subscriptionResult, dispatch);
    await processDomainInformation(domainInfoResult, user, dispatch);
    await processContactsAndLabels(contactsResult, user, dispatch);
    await dispatch(updateStatusFirstLoad({statusFirstLoad: 'succeeded'}));

    return [getUserResult, subscriptionResult, contactsResult, domainInfoResult];
};

async function processSubscriptionData(subscriptionResult, dispatch) {
    if (!subscriptionResult) return;
    const subscription = subscriptionResult.payload;
    dispatch(setSubscriptionType(subscription.status !== 0 ? subscription.plan_id === 2 ? 'monthly' : 'annually' : 'annually'));
    dispatch(setCurrency(subscription.status !== 0 ? subscription.plan_id !== 1 ? subscription.subscription.currency : 'USD' : 'USD'));
    const SUBSCRIPTION_OVER = subscription.plan_id !== 1 && subscription.status === 0;
    const TRIAL = subscription.plan_id === 1;
    let counter = (!TRIAL && subscription.status === 1) ? subscription.quantity : subscription.connected_users.length;
    counter = Math.max(counter, 2);
    if (subscription.subscription.version === 2) {
        const result = usersCountToPlanCount(counter);
        counter = result.to;
    }
    dispatch(setCounter(counter));
    const amount = await dispatch(getAmount({
        period: subscription.status !== 0 ? subscription.plan_id === 2 ? 'MONTHLY' : 'YEAR' : 'YEAR',
        quantity: counter,
        version: SUBSCRIPTION_OVER || TRIAL ? 3 : subscription.subscription.version,
        currency: subscription.subscription.currency,
    }));
    dispatch(setPricePerUser(counter ? Math.trunc((amount.payload.price / counter) * 100) / 100 : 0));
}

async function processDomainInformation(domainInfoResult, user, dispatch) {
    if (!domainInfoResult) return;
    const domainInformation = domainInfoResult.payload;
    const shareableDomainUsers = domainInformation.users.filter(
        elem => elem.email && elem.email.toLowerCase() !== user.email.toLowerCase()
    )
    const users = shareableDomainUsers.map(el => {
        if (el.email) {
            return {
                email: el.email,
                name: el.name.fullName,
                photo: el.photo,
                group: false,
                domainUser: true
            }
        }
        return null
    })
    await dispatch(updateShareableUsers(users));
    await dispatch(setDomainData(domainInformation));
    const domainsGroups = domainInformation.groups;
    if (domainsGroups && domainsGroups.length) {
        const users = domainsGroups.map(el => {
            if (el.email) {
                return {email: el.email, name: el.name, photo: null, group: true}
            }
            return null
        })
        await dispatch(updateShareableUsers(users));
    }
}

async function processContactsAndLabels(groupsAndContacts, user, dispatch) {
    if (!groupsAndContacts) return;
    // const contacts = contactsResult.payload;
    dispatch(updateGroups({groups: groupsAndContacts.payload.groups, user}));
    const shareableContacts = groupsAndContacts.payload.contacts.reduce((acc, item) => {
        if (item.email && item.email.toLowerCase() !== user.email.toLowerCase()) {
            acc.push({
                email: item.email,
                name: item.name,
                photo: item.photo,
                group: false,
            });
        }
        return acc;
    }, []);
    await dispatch(updateShareableUsers(shareableContacts));
}
