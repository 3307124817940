import * as React from "react";
import { Alert, Button, Box, Typography } from "@mui/material";
import { FileDownload } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';

// project import
import { getContactsAndLabels } from "../../store/reducers/contactsSlice";
import { updateGroups } from "../../store/reducers/groupsSlice";
import { setDomainInstallAlert } from "../../store/reducers/userSlice";
import { ALERT_HEIGHT, HEADER_HEIGHT } from "../../constants/size";

export default function DomainInstallAlert() {
    const user = useSelector((state) => state.user.user);
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    const dispatch = useDispatch();
    const handleClickDomainInstall = () => {
        const publicUrl = process.env.REACT_APP_PUBLIC_URL || 'https://sharedcontactsmanager.com/app/';
        const urlStr = `https://workspace.google.com/marketplace/dwi/211122818894?redirect_url=${publicUrl}`;
        const newWindow = window.open(urlStr, "myWindow", 'width=800,height=800');
        const timerCurrent = setInterval(async () => {
            if (newWindow.closed) {
                clearInterval(timerCurrent);
                const groupsAndContacts = await dispatch(getContactsAndLabels());
                dispatch(updateGroups({ groups: groupsAndContacts.payload.groups, user }));
            }
        }, 1000);
        dispatch(setDomainInstallAlert(false));
    };
    return (
        domainInstallAlert && (<Box
            sx={{
                position: 'fixed',
                top: `${HEADER_HEIGHT}px`,
                left: 0,
                width: '100%',
                height: `${ALERT_HEIGHT}px`,
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
        >
            <Alert
                sx={{ height: '100', background: '#FDF0E6' }}
                severity="warning"
                action={
                    user.domain_admin ?
                        <Button color="inherit" size="small" startIcon={<FileDownload />}
                            sx={{ height: '30px', marginTop: '-1px', marginRight: 2 }}
                            onClick={handleClickDomainInstall}
                        >
                            Domain install
                        </Button> : ''
                }>
                <Typography sx={typographyStyles}>
                    {user.domain_admin ? <MessageForAdmin /> : <MessageForNotAdmin />}
                </Typography>
            </Alert>
        </Box>)
    )
}

const MessageForAdmin = () => {
    return 'To enable all features of Shared Contacts Manager, please install the app across the whole domain.';
}

const MessageForNotAdmin = () => {
    return <>
        To enable all features of Shared Contacts Manager, please ask your <Link
            to="https://support.google.com/a/answer/6208960?hl=en" target={"_blank"} rel="noreferrer"
            style={{
                color: 'rgba(95, 43, 1, 1)',
            }}><Box
                sx={{
                    color: 'rgba(95, 43, 1, 1)',
                    "&:hover": {
                        color: 'rgba(237, 108, 2, 1)',
                    },
                    display: 'inline',
                }}
            >
                domain administrator
            </Box></Link> to install the app domain-wide.
    </>
};

// Styles
const typographyStyles = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "0.17px",
    color: "#5F2B01",
};
