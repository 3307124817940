import React, { useState } from 'react';
import { Box, Typography, useTheme, Grid, SvgIcon } from '@mui/material';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Info } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { LicensedUsersIcon, NoLicensedUsersIcon } from "./LicenseIcons";

const Card = ({ title, icon: Icon, value, showBuyLink, hidden, isNotDomainUser }) => {
    const theme = useTheme();

    if (hidden) return null;

    return (
        <Box
            sx={{
                boxSizing: 'border-box',
                width: isNotDomainUser ? 440 : '100%',
                maxWidth: isNotDomainUser ? 440 : 326,
                minWidth: 240,
                height: 96,
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: '6px',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: 2,
                transition: 'width 0.3s ease',
            }}
        >
            <Grid container justifyContent="space-between">
                <Typography
                    variant="body2"
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        color: theme.palette.text.primary,
                        marginBottom: 1,
                        fontSize: 16,
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.15px',
                    }}
                >
                    {title}
                </Typography>
                {/* <Info sx={{ color: '#ADADAD' }} /> */}
            </Grid>
            <Stack spacing={'4px'} direction="row" sx={{ mt: 1, alignItems: 'center' }}>
                <Icon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                <Stack spacing={'16px'} direction="row" alignItems="center">
                    <Box>
                        <Typography
                            sx={{
                                fontFamily: 'Roboto',
                                fontSize: 16,
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '24px',
                                letterSpacing: '0.15px',
                            }}
                        >
                            {value}
                        </Typography>
                    </Box>
                    {showBuyLink && (
                        <Link
                            to="/manage_subscription/change_subscription"
                            style={{
                                color: '#3B7DED',
                                textDecoration: 'none',
                            }}>
                            <Box
                                sx={{
                                    color: '#3B7DED',
                                    fontFamily: 'Roboto',
                                    fontSize: 16,
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: '24px',
                                    letterSpacing: '0.15px',
                                    whiteSpace: 'nowrap',
                                    display: 'inline',
                                }}
                            >
                                Buy licenses
                            </Box>
                        </Link>
                    )}
                </Stack>
            </Stack>
        </Box>
    );
};

const CardsContainer = () => {
    const user = useSelector((state) => state.user);
    const domainUsersCount = useSelector((state) => state.user.shareableUsers.domainUsersCount);
    const subscription = useSelector((state) => state.user.subscription);
    const usersWithLicense = subscription.active_quantity;
    let deletedUsers = [];
    let bannedUsers = [];
    bannedUsers = subscription.restricted_users || [];
    deletedUsers = subscription.deleted_users?.filter(deletedUser =>
        !subscription.restricted_users.some(restrictedUser => restrictedUser.email === deletedUser.email)
    ) || [];
    let usersWithoutLicense = deletedUsers.length + bannedUsers.length;
    const isNotDomainUser = !user.user.domain_user;

    if (!isNotDomainUser) {
        const unlicensedDomainUsers = user.domainData?.users?.filter(domainUser =>
            !subscription.licensed_users.some(licensedUser => licensedUser.email === domainUser.email)
        ) || [];
        const isNotUnique = (user, users) => users.some(existingUser => existingUser.email === user.email);
        const uniqueUnlicensedDomainUsers = unlicensedDomainUsers.filter(
            user => !isNotUnique(user, deletedUsers) && !isNotUnique(user, bannedUsers)
        );
        usersWithoutLicense += uniqueUnlicensedDomainUsers.length;
    }
    const availableLicenses = subscription.quantity === 0 ? 0 : subscription.quantity - usersWithLicense;
    const availableLicensesText = `${availableLicenses}/${subscription.quantity}`;

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 3,
                width: '100%',
                maxWidth: 1376,
                marginTop: 3,
                flexWrap: 'nowrap',
                transition: 'gap 0.3s ease',
                '@media (max-width: 1200px)': {
                    gap: 2,
                },
                '@media (max-width: 1070px)': {
                    gap: 1,
                },
                '@media (max-width: 1050px)': {
                    flexWrap: 'wrap',
                    gap: 2,
                },
            }}
        >
            <Card title="Available licenses" icon={BookmarkBorderOutlinedIcon} value={availableLicensesText} showBuyLink isNotDomainUser={isNotDomainUser} />
            <Card title="Users with licenses" icon={LicensedUsersIcon} value={usersWithLicense} isNotDomainUser={isNotDomainUser} />
            <Card title="Users without licenses" icon={NoLicensedUsersIcon} value={usersWithoutLicense} isNotDomainUser={isNotDomainUser} />
            <Card title="Google Workspace users" icon={PeopleAltOutlinedIcon} value={domainUsersCount || 0} hidden={!user.user.domain_user} isNotDomainUser={isNotDomainUser} />
        </Box>
    );
};

export default CardsContainer;
