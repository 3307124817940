import * as React from "react";
import {useSelector} from "react-redux";

import DomainInstallAlert from "./DomainInstallAlert";
import NoLicense from "./NoLicense";

export default function AlertsBar() {
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    const noLicenseAlert = useSelector((state) => state.user.noLicenseAlert);
    let component = null;
    if (domainInstallAlert && !noLicenseAlert) {
        component = <DomainInstallAlert/>;
    }
    if (noLicenseAlert) {
        component = <NoLicense/>;
    }
    return component;
}
