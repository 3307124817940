import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import {
    Button,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
    Stack,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

// project import
// import { openWelcomeDialog } from "../../store/reducers/dialogSlice";
import { updateDomainIsFirstTime } from "../../store/reducers/userSlice";
import store from "../../store/store";

const WelcomeDialog = () => {
    // const open = useSelector((state) => state.dialogsOpen.welcome.value);
    const open = useSelector((state) => state.user.domainIsFirstTime);
    const handleClose = () => {
        // store.dispatch(openWelcomeDialog(false));
        store.dispatch(updateDomainIsFirstTime(false));
    };
    return (
        <Dialog open={open} onClose={handleClose} fullWidth id='WelcomeDialog'
            PaperProps={{
                sx: {
                    width: 720,
                    maxWidth: 720,
                    minWidth: 444,
                    backgroundColor: 'rgba(59, 125, 237, 1)',
                }
            }}
        >
            <DialogTitle sx={{ backgroundColor: 'rgba(59, 125, 237, 1)' }}>
                <Grid container sx={{ alignItems: 'center' }}>
                    <Grid item>
                        <Typography
                            variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Welcome to Shared Contacts Manager!</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: 'rgba(255, 255, 255, 0.54)',
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        backgroundColor: 'rgba(0, 0, 0, 0.16)'
                    },
                }}
            >
                <CloseOutlined />
            </IconButton>
            <DialogContent dividers sx={{ border: 0, backgroundColor: 'rgba(255, 255, 255, 1)', overflow: 'hidden' }}>
                <CardMedia
                    component="video"
                    image={`${process.env.REACT_APP_PUBLIC_URL}assets/video/welcomeIntro.mp4`}
                    title="Welcome to Shared Contacts Manager"
                    autoPlay={false}
                    controls
                />
            </DialogContent>
            <DialogActions sx={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                <Button onClick={handleClose}>
                    <Typography variant={'dialogButton'} color={'rgba(59, 125, 237, 1)'}>Start free trial</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default WelcomeDialog;
