import * as React from "react";
import { useSelector } from "react-redux";
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

// project import
import ContactsPage from "./pages/ContactsPage";
import ManageSubscriptionPage from "./pages/ManageSubscriptionPage";
import ManageUsersPage from "./pages/ManageUsersPage";
import ChangeLicenseCountPage from "./pages/ChangeLicenseCountPage";
import FallbackError from "./components/FallbackError/FallbackError";
import MainLayout from "./layouts/MainLayout";
import Page404 from './pages/Page404';
import MobilePage from "./components/MobilePage/MobilePage";
import { isSubscriptionOver, isTrialNotOver, isTrialOver } from "./services/subscriptionStatus";
// import TrialSubscriptionOver from "./components/Trial/TrialSubscriptionOver";
import TrialPage from "./pages/TrialPage";

export default function Router() {
    const subscription = useSelector((state) => state.user.subscription);
    const matches = useMediaQuery('(min-width:993px)'); // <= 992px - mobile, >= 993px desktop
    const router = matches ? mainRoute(isTrialOver, subscription) : mobileRoute();
    return <RouterProvider router={router}/>;
}

const mainRoute = (isTrialOver, subscription) => {
    return createBrowserRouter(
        [
            {
                errorElement: <FallbackError />,
                children: [
                    {
                        path: "/",
                        element: isTrialOver(subscription) || isSubscriptionOver(subscription) ?
                            <TrialPage /> : <ContactsPage />,
                    },
                    {
                        path: "manage_users",
                        element: <ManageUsersPage />,
                    },
                    {
                        path: "manage_subscription",
                        element: <MainLayout />,
                        children: [
                            {
                                element: <ManageSubscriptionPage />,
                                index: true
                            },
                            {
                                path: "change_subscription",
                                element: <ChangeLicenseCountPage />,
                            },
                        ]
                    },
                    { path: '404', element: <Page404 /> },
                    { path: '*', element: <Navigate to="/404" /> },
                    // {
                    //     path: "trial_over",
                    //     element: <TrialPage type={'trial_over'}/>,
                    // },
                    // {
                    //     path: "trial_checking_eligibility",
                    //     element: <TrialPage type={'checking_eligibility'}/>,
                    // },
                    // {
                    //     path: "trial_congratulations",
                    //     element: <TrialPage type={'congratulations'}/>,
                    // },
                    // {
                    //     path: "trial_sorry",
                    //     element: <TrialPage type={'sorry'}/>,
                    // },
                    // {
                    //     path: "trial_sorry",
                    //     element: <TrialPage type={'sorry'}/>,
                    // },
                ],
            },
        ],
        {
            basename: process.env.REACT_APP_PREFIX,
        }
    );
}

const mobileRoute = () => {
    return createBrowserRouter(
        [
            {
                errorElement: <FallbackError />,
                children: [
                    { path: '*', element: <MobilePage /> },
                ],
            },
        ],
        {
            basename: process.env.REACT_APP_PREFIX,
        }
    );
}
