import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from "react-redux";
import { Grid, IconButton, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";

// project import
import store from "../../store/store";
import { openRemoveAdminPermissionsDialog } from "../../store/reducers/dialogSlice";
import {
    getSubscriptionData,
    subscriptionSetAdmin,
    setUsersToRemoveAdminPermissions,
    setSelectedUsers,
} from "../../store/reducers/userSlice";
import { showNotification } from "../../store/reducers/notificationSlice";

export default function AlertRemoveAdminPermissionsDialog() {
    const open = useSelector((state) => state.dialogsOpen.removeAdminPermissions.value);
    const user = useSelector((state) => state.user.user);
    const usersToRemoveAdminPermissions = useSelector((state) => state.user.usersToRemoveAdminPermissions);
    const subscriptionSetAdminStatus = useSelector((state) => state.user.subscriptionSetAdminStatus);
    if (!open) {
        return null;
    }
    const loading = subscriptionSetAdminStatus === 'loading';
    const handleClose = () => {
        store.dispatch(openRemoveAdminPermissionsDialog(false));
    };
    const handleDeleteUsers = async () => {
        const users = {
            setAdmin: false,
            emails: usersToRemoveAdminPermissions.selectedUsers.map(user => user.email),
        }
        await store.dispatch(subscriptionSetAdmin(users));
        store.dispatch(setUsersToRemoveAdminPermissions([]));
        store.dispatch(setSelectedUsers([]));
        handleClose();
        await store.dispatch(getSubscriptionData());
    };
    const hasAdmin = usersToRemoveAdminPermissions.selectedUsers?.some(item => item.isAdmin);
    // Filter all admins from rowUsers
    const adminsInRowUsers = usersToRemoveAdminPermissions.rowsUsers?.filter(user => user.isAdmin);
    // Check if all admins from rowUsers are selected
    const allAdminsSelected = adminsInRowUsers?.every(admin =>
        usersToRemoveAdminPermissions.selectedUsers?.some(user => user.id === admin.id)
    );
    const isSelectedMe = usersToRemoveAdminPermissions.selectedUsers?.some(
        selectedUser => selectedUser.userId === user.user_id
    ) || false;
    let message;

    if (allAdminsSelected) {
        message = 'Admin access cannot be removed from all users. Please ensure there is at least one admin remaining.';
    } else if (isSelectedMe) {
        message = 'You are about to remove admin access from your account. You will lose the ability to manage user licences and the subscription.';
    } else {
        const isMultipleUsers = usersToRemoveAdminPermissions.selectedUsers?.length > 1;
        message = `Are you sure you want to delete admin permissions from user${isMultipleUsers ? 's' : ''}?`;
    }

    const CancelButton = () => {
        if (allAdminsSelected) {
            return null;
        }
        return (
            <Button onClick={handleClose}>
                <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Cancel</Typography>
            </Button>
        );
    };
    const CloseButton = () => {
        if (!allAdminsSelected) {
            return null;
        }
        return (
            <Button onClick={handleClose}>
                <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Close</Typography>
            </Button>
        );
    };
    const ConfirmButton = () => {
        if (allAdminsSelected) {
            return null;
        }
        return (
            <LoadingButton
                loading={loading}
                disabled={loading}
                onClick={handleDeleteUsers}
            >
                <Typography variant={'dialogButton'} sx={{
                    color: loading ? 'rgba(0, 0, 0, 0.32)' : 'rgba(59, 125, 237, 1)'
                }}>Confirm</Typography>
            </LoadingButton>
        );
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-delete-licenses-dialog-title"
            aria-describedby="alert-delete-licenses-dialog-description"
            PaperProps={{
                sx: {
                    minWidth: 444,
                    backgroundColor: 'rgba(59, 125, 237, 1)'
                }
            }}
        >
            <DialogTitle sx={{ backgroundColor: 'rgba(59, 125, 237, 1)' }}>
                <Grid container sx={{ alignItems: 'center' }}>
                    <Grid item>
                        <Typography
                            variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Warning</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: 'rgba(255, 255, 255, 0.54)',
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        backgroundColor: 'rgba(0, 0, 0, 0.16)'
                    },
                }}
            >
                <CloseOutlined />
            </IconButton>
            <DialogContent dividers sx={{ border: 0, backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                <Typography
                    sx={{
                        color: 'rgba(0, 0, 0, 0.87)',
                        fontSize: 16,
                        fontFamily: `"Roboto"`,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.2px',
                    }}
                >
                    {message}
                </Typography>
            </DialogContent>
            <DialogActions sx={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                <CancelButton />
                <CloseButton />
                <ConfirmButton />
            </DialogActions>
        </Dialog>
    );
}

