import * as React from "react";
import {Box, Button, Container, Stack, SvgIcon, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {MonetizationOnOutlined, SupervisedUserCircleOutlined} from "@mui/icons-material";
import {isSubscriptionOver as checkSubscriptionOver} from "../../services/subscriptionStatus";

export default function TrialSubscriptionOver() {
    const navigate = useNavigate();
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    const noLicenseAlert = useSelector((state) => state.user.noLicenseAlert);
    const isAlertBar = domainInstallAlert || noLicenseAlert;
    const subscription = useSelector((state) => state.user.subscription);
    const isSubscriptionOver = checkSubscriptionOver(subscription)
    const mainText = isSubscriptionOver ? 'Subscription expired' : 'Your trial is over';
    return (
        <Container disableGutters sx={{width: '560px'}}>
            <Stack
                spacing={1}
                sx={{
                    display: 'flex',
                    width: '100%',
                    minHeight: isAlertBar ? 'calc(100vh - 64px - 48px)' : 'calc(100vh - 64px)',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <Box
                    sx={{
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '56px 0px 0px',
                        gap: '40px',
                        // position: 'absolute',
                        width: '560px',
                        height: '428px',
                        // left: 'calc(50% - 560px/2)',
                        // top: 'calc(50% - 428px/2)',
                        background: 'rgba(255, 255, 255, 0.08)',
                        border: '1px solid #E0E0E0',
                        borderRadius: '8px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: '0px 80px',
                            gap: '24px',
                            width: '560px',
                            height: '260px',
                            flex: 'none',
                            order: 0,
                            alignSelf: 'stretch',
                            flexGrow: 0,
                        }}
                    >
                        <Box
                            sx={{
                                width: '80px',
                                height: '80px',
                                flex: 'none',
                                order: 0,
                                flexGrow: 0,
                                position: 'relative',
                            }}
                        >
                            <SvgIcon sx={{width: 80, height: 80, marginBottom: 2}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96"
                                     fill="none">
                                    <rect width="96" height="96" rx="48" fill="#FFC6C2"/>
                                    <path
                                        d="M47.968 16C30.304 16 16 30.336 16 48C16 65.664 30.304 80 47.968 80C65.664 80 80 65.664 80 48C80 30.336 65.664 16 47.968 16Z"
                                        fill="white"/>
                                    <path
                                        d="M47.968 16C30.304 16 16 30.336 16 48C16 65.664 30.304 80 47.968 80C65.664 80 80 65.664 80 48C80 30.336 65.664 16 47.968 16ZM58.528 63.072L44.8 49.312V32H51.2V46.688L63.072 58.56L58.528 63.072Z"
                                        fill="#E43E2B"/>
                                </svg>
                            </SvgIcon>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '0px',
                                gap: '8px',
                                width: '400px',
                                height: '88px',
                                flex: 'none',
                                order: 1,
                                flexGrow: 0,
                            }}
                        >
                            <Typography
                                sx={{
                                    width: isSubscriptionOver ? '231px' : '174px',
                                    height: '32px',
                                    fontFamily: 'Open Sans',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    fontSize: '24px',
                                    lineHeight: '32px',
                                    letterSpacing: '-0.02em',
                                    color: '#3C4043',
                                    flex: 'none',
                                    order: 0,
                                    flexGrow: 0,
                                }}
                            >
                                {mainText}
                            </Typography>

                            <Typography
                                sx={{
                                    width: '400px',
                                    height: '48px',
                                    fontFamily: 'Open Sans',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    textAlign: 'center',
                                    letterSpacing: '-0.02em',
                                    color: 'rgba(102, 102, 102, 1)',
                                    flex: 'none',
                                    order: 1,
                                    flexGrow: 0,
                                }}
                            >
                                Buy a subscription to continue with Shared Contacts Manager
                            </Typography>
                        </Box>

                        <Button
                            variant='contained'
                            onClick={() => navigate("/manage_subscription/change_subscription")}
                            sx={{
                                width: '400px',
                                height: '44px',
                                flex: 'none',
                                order: 2,
                                flexGrow: 0,
                                textTransform: "none",
                                letterSpacing: 0,
                            }}
                        >
                            Buy subscription
                        </Button>
                    </Box>

                    <Box
                        sx={{
                            boxSizing: 'border-box',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            padding: '0px',
                            width: '560px',
                            height: '72px',
                            borderTop: '1px solid #E0E0E0',
                            borderRight: '0',
                            borderBottom: '0',
                            borderLeft: '0',
                            flex: 'none',
                            order: 1,
                            alignSelf: 'stretch',
                            flexGrow: 0,
                        }}
                    >
                        <Button
                            onClick={() => navigate("/manage_subscription")}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '24px 20px',
                                gap: '8px',
                                width: '280px',
                                height: '72px',
                                flex: 'none',
                                order: 0,
                                flexGrow: 1,
                            }}
                        >
                            <MonetizationOnOutlined sx={{
                                width: '20px',
                                height: '20px',
                                flex: 'none',
                                order: 0,
                                flexGrow: 0,
                                color: 'rgba(0, 0, 0, 0.54)',
                            }}/>
                            <Typography
                                sx={{
                                    width: '142px',
                                    height: '24px',
                                    fontFamily: 'Open Sans',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    lineHeight: '24px',
                                    color: '#000000',
                                    flex: 'none',
                                    order: 1,
                                    flexGrow: 0,
                                    textTransform: "none",
                                    letterSpacing: 0,
                                }}
                            >
                                Manage subscription
                            </Typography>
                        </Button>

                        <Button
                            onClick={() => navigate("/manage_users")}
                            sx={{
                                boxSizing: 'border-box',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '24px 20px',
                                gap: '8px',
                                width: '280px',
                                height: '72px',
                                borderLeft: '1px solid #E0E0E0',
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                flex: 'none',
                                order: 1,
                                flexGrow: 1,
                            }}
                        >
                            <SupervisedUserCircleOutlined sx={{
                                width: '20px',
                                height: '20px',
                                flex: 'none',
                                order: 0,
                                flexGrow: 0,
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}/>
                            <Typography
                                sx={{
                                    width: '95px',
                                    height: '24px',
                                    fontFamily: 'Open Sans',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    lineHeight: '24px',
                                    color: '#000000',
                                    flex: 'none',
                                    order: 1,
                                    flexGrow: 0,
                                    textTransform: "none",
                                    letterSpacing: 0,
                                }}
                            >
                                Manage users
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Stack>
        </Container>
    );
};
