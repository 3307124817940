import {useEffect} from "react";
import {useDispatch} from "react-redux";

import store from "../store/store";
import {openHelpCrunchChat} from "../store/reducers/dialogSlice";
import {setGA4ClientId} from "../store/reducers/dataSlice";

export default function useAnalytics(user) {
    const dispatch = useDispatch();
    useEffect(() => {
        if (user.userInitialized && user.subscriptionInitialized) {
            const subscription = user.subscription;
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                scm_is_domain_user: user.user.domain_user,
            });
            const isAdmin = Number(subscription.status === 1);
            window.dataLayer.push({scm_is_admin_user: isAdmin})
            window.dataLayer.push({scm_email: user.user.email})
            window.dataLayer.push({userId: user.user.user_id})
            window.dataLayer.push({
                event: 'userSet'
            })
            if (window.clarity) {
                window.clarity("identify", user.user.email)
            }
            const setYM = () => {
                if (window.ym) {
                    window.ym(84600490, 'userParams', {
                        email: user.user.email
                    })
                } else {
                    setTimeout(() => {
                        setYM()
                    }, 2000)
                }
            }
            setYM();
            if (window.mixpanel) {
                window.mixpanel.identify(user.user.email);
                window.mixpanel.people.set({
                    $email: user.user.email, // only reserved properties need the $
                    USER_ID: user.user.email // use human-readable names
                });
                window.mixpanel.track('LOADED', {
                    admin: isAdmin
                })
            }
            // if (window.amplitude) {
            //     // console.log('AMPLITUDE')
            //     window.amplitude.getInstance().setUserId(user.email)
            //     window.amplitude.getInstance().logEvent('LOADED')
            // }

            if (window.LiveChatWidget) {
                // console.log('LiveChatWidget')
                window.LiveChatWidget.call(
                    'set_customer_email',
                    user.user.email
                )
            }
            window.HelpCrunch('onReady', () => {
                const data = {
                    email: user.user.email,
                };
                window.HelpCrunch('updateUser', data);
                window.HelpCrunch('onChatClose', () => {
                    store.dispatch(openHelpCrunchChat(false))
                });
            });
            const isPaid = Number(subscription.plan_id !== 1);
            window.dataLayer.push({scm_paid: isPaid});
            if (user.domainIsFirstTime) {
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                    event: 'DOMAIN_FIRST_TIME_LOGIN'
                })
                window.dataLayer.push({
                    is_first_domain_enter: 1
                })
            }
            window.dataLayer.push({
                event: 'optimize.activate',
            });
            window.bugsnagUserEmail = user.user.email;
            window.bugsnagUserId = user.user.user_id;
            try {
                if (window.ga) {
                    const gaAll = window.ga.getAll();
                    if (gaAll) {
                        const clientId = gaAll[0].get('clientId');
                        dispatch(setGA4ClientId({clientId}));
                    }
                }
            } catch (error) {
                console.log("GA4 error")
            }
        }
    }, [user.userInitialized, user.subscriptionInitialized]);
}
