import { IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import { PersonAddAltOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import * as React from "react";

// project import
import store from "../../store/store";
import { openAddUserToSubscriptionDialog } from "../../store/reducers/dialogSlice";

export default function ManageUsersAppBar() {
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    const noLicenseAlert = useSelector((state) => state.user.noLicenseAlert);
    const isAlertBar = domainInstallAlert || noLicenseAlert;
    const subscription = useSelector((state) => state.user.subscription);
    const isDisabled = () => {
        return subscription.status === 2 || subscription.status === 0 || subscription.quantity === subscription.active_quantity;
    }
    const AddUsersToSubscriptionButton = () => {
        return <Tooltip title='Add users' placement="bottom">
            <span>
                <IconButton onClick={() => store.dispatch(openAddUserToSubscriptionDialog(true))}
                    disabled={isDisabled()}>
                    <PersonAddAltOutlined />
                </IconButton>
            </span>
        </Tooltip>
    }
    return (
        <>
            {/* <Toolbar sx={{height: 73}}/> */}
            <Toolbar sx={{ marginTop: 1 }}>
                <Typography
                    variant={'subtitle2'}
                    noWrap
                    sx={{
                        ml: -1.2,
                        width: 'auto',
                        maxWidth: '200px',
                        paddingRight: '8px',
                    }}
                >
                    Users
                </Typography>
                <AddUsersToSubscriptionButton />
            </Toolbar>
        </>
    )
}
