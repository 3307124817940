import {useSelector} from "react-redux";

// project import
import {Breadcrumbs, Toolbar, Typography} from "@mui/material";
import {NavigateNext} from "@mui/icons-material";
import * as React from "react";
import {Link as RouterLink, useLocation} from "react-router-dom";
import Link from "@mui/material/Link";
import {ALERT_HEIGHT, HEADER_HEIGHT} from "../constants/size";


export default function CustomBreadcrumbs() {
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    const noLicenseAlert = useSelector((state) => state.user.noLicenseAlert);
    const showAlert = domainInstallAlert || noLicenseAlert;
    const location = useLocation();
    const pathNames = location.pathname.split('/').filter((x) => x);

    function LinkRouter(props) {
        return <Link {...props} component={RouterLink}/>;
    }

    const breadcrumbNameMap = {
        '/manage_subscription': 'Manage Subscription',
        '/manage_subscription/change_subscription': 'Change subscription',
        '/manage_users': 'Manage Users',
    };
    const topOffset = showAlert ? HEADER_HEIGHT + ALERT_HEIGHT : HEADER_HEIGHT; // Calculate the total height of Header + Alert if Alert is displayed
    return (
        pathNames.length ? <Toolbar sx={{top: `${topOffset}px`}}>
            <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNext fontSize="small"/>}>
                <LinkRouter underline="none" color="inherit" to="/">
                    <Typography
                        sx={{
                            color: 'rgba(0, 0, 0, 0.6)',
                            fontFamily: "Roboto",
                            fontStyle: 'normal',
                            fontWeight: 'Regular',
                            fontSize: 16,
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                        }}
                    >
                        Shared Contacts Manager
                    </Typography>
                </LinkRouter>
                {pathNames.map((value, index) => {
                    const last = index === pathNames.length - 1;
                    const to = `/${pathNames.slice(0, index + 1).join('/')}`;
                    return last ? (
                        <Typography key={to}
                                    sx={{
                                        color: 'rgba(0, 0, 0, 0.87)',
                                        fontFamily: "Roboto",
                                        fontStyle: 'normal',
                                        fontWeight: 'Regular',
                                        fontSize: 16,
                                        lineHeight: '24px',
                                        letterSpacing: '0.15px',
                                    }}
                        >
                            {breadcrumbNameMap[to]}
                        </Typography>
                    ) : (
                        <LinkRouter underline="none" color="inherit" to={to} key={to}>
                            <Typography color="text.primary" key={to}
                                        sx={{
                                            color: 'rgba(0, 0, 0, 0.6)',
                                            fontFamily: "Roboto",
                                            fontStyle: 'normal',
                                            fontWeight: 'Regular',
                                            fontSize: 16,
                                            lineHeight: '24px',
                                            letterSpacing: '0.15px',
                                        }}
                            >
                                {breadcrumbNameMap[to]}
                            </Typography>
                        </LinkRouter>
                    );
                })}
            </Breadcrumbs>
        </Toolbar> : null
    )
}
