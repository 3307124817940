import React, {useState} from 'react';
import {
    Toolbar,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    CssBaseline,
    Box,
    Divider,
} from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {useSelector} from "react-redux";

// project import
import Header from "../components/Header/Header";
import DomainInstallAlert from "../components/Alerts/DomainInstallAlert";
import {
    ALERT_HEIGHT,
    DRAWER_WIDTH,
    HEADER_HEIGHT,
} from "../constants/size";
import NoLicense from "../components/Alerts/NoLicense";
import SidebarLabels from "../components/SideBarLabels/SideBarLabels";
import ContactsAppBar from "../components/Contacts/ContactsAppBar";
import Contacts from "../components/Contacts/Contacts";
import AlertsBar from "../components/Alerts/AlertsBar";


const Layout = () => {
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    const noLicenseAlert = useSelector((state) => state.user.noLicenseAlert);
    const showAlert = domainInstallAlert || noLicenseAlert;
    const [mobileOpen, setMobileOpen] = useState(false); // For mobile menu
    const topOffset = showAlert ? HEADER_HEIGHT + ALERT_HEIGHT : HEADER_HEIGHT; // Calculate the total height of Header + Alert if Alert is displayed
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    // Side menu (Drawer) for mobile devices
    const drawer = (
        <div>
            <Toolbar/>
            <Box sx={{overflow: 'auto'}}>
                <List>
                    {['Inbox', 'Sent', 'Drafts', 'Deleted'].map((text, index) => (
                        <ListItem button key={text}>
                            <ListItemIcon>{index % 2 === 0 ? <InboxIcon/> : <MailIcon/>}</ListItemIcon>
                            <ListItemText primary={text}/>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </div>
    );
    return (
        <Box sx={{display: 'flex', height: '100vh'}}>
            <CssBaseline/>
            {/* Header */}
            <Header/>
            {/* Alert */}
            <AlertsBar/>
            {/* Sidebar (Drawer) */}
            <Box
                component="nav"
                sx={{width: {sm: DRAWER_WIDTH}, flexShrink: {sm: 0}}}
                aria-label="mailbox folders"
            >
                {/* Temporary Drawer for mobile devices */}
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Improves performance on mobile devices
                    }}
                    sx={{
                        display: {xs: 'block', sm: 'none'},
                        [`& .MuiDrawer-paper`]: {
                            width: DRAWER_WIDTH,
                            boxSizing: 'border-box',
                            top: `${topOffset}px`,
                            height: `calc(100% - ${topOffset}px)`,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                {/* Permanent Drawer for large screens */}
                <Drawer
                    variant="permanent"
                    sx={{
                        display: {xs: 'none', sm: 'block'},
                        [`& .MuiDrawer-paper`]: {
                            width: DRAWER_WIDTH,
                            boxSizing: 'border-box',
                            top: `${topOffset}px`,
                            height: `calc(100% - ${topOffset}px)`,
                            border: 0,
                        },
                    }}
                    open
                >
                    <SidebarLabels/>
                </Drawer>
            </Box>

            {/* Main content */}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.default',
                    // p: 2, // Reduced padding to prevent overflow
                    marginTop: `${topOffset}px`,
                    marginLeft: 4,
                    height: `calc(100vh - ${topOffset}px)`,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {/* Toolbar above the table */}
                <ContactsAppBar/>
                <Divider/>
                {/* Wrapper for DataGrid */}
                <Box sx={{flexGrow: 1, overflow: 'hidden', width: '100%', marginTop: 2}}>
                    <Contacts topOffset={topOffset}/>
                </Box>
            </Box>
        </Box>
    );
};

export default Layout;
