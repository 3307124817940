import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Box, Grid, Stack, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';
import {ErrorOutline} from "@mui/icons-material";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";

// project import
import QuantityLicenses from "./QuantityLicenses";
import {
    calculateAmountChanges,
    getAmount,
    subscriptionChangeLicensesCount,
    subscriptionChangeLicensesToAnnual,
    setPricePerUser, setSubscriptionType, setCurrency,
} from "../../store/reducers/userSlice";
import {getBillingPeriod} from "../../services/getPeriod";
import ReviewAndCheckoutDialog from "./ReviewAndCheckoutDialog";
import store from "../../store/store";
import {openReviewAndCheckoutDialog} from "../../store/reducers/dialogSlice";
import {showNotification} from "../../store/reducers/notificationSlice";
import {SkeletonQuantityLicenses} from "../Skeletons";

export function ChangeLicenseCount() {
    const dispatch = useDispatch();
    const subscriptionInitialized = useSelector((state) => state.user.subscriptionInitialized);
    const subscription = useSelector((state) => state.user.subscription);
    const updateSubscriptionStatus = useSelector((state) => state.user.updateSubscriptionStatus);
    const amount = useSelector((state) => state.user.amount);
    const currency = useSelector((state) => state.user.currency);
    const counter = useSelector((state) => state.user.counter);
    const subscriptionType = useSelector((state) => state.user.subscriptionType);
    const pricePerUser = useSelector((state) => state.user.pricePerUser);
    const statusFirstLoad = useSelector((state) => state.user.statusFirstLoad);
    const user = useSelector((state) => state.user);
    const [error, setError] = React.useState(false);
    if (!subscriptionInitialized) return null;
    const SUBSCRIPTION_OVER = subscription.plan_id !== 1 && subscription.status === 0;
    const TRIAL = subscription.plan_id === 1;
    const subscriptionVersion = SUBSCRIPTION_OVER || TRIAL ? 3 : subscription.subscription.version;
    const currencySign = currency === 'EUR' ? '€' : '$';
    const trialCodes = {
        'monthly': 2,
        'annually': 3,
    };
    const disableUpdateButton = () => {
        if (subscription.status === 0) return false
        if (subscription.quantity !== counter) return false;
        return trialCodes[subscriptionType] === subscription.plan_id;
    }
    const handleChangeBillingCycle = async (event, newSubscriptionType) => {
        if (!newSubscriptionType) return;
        const data = {
            period: newSubscriptionType === 'monthly' ? 'MONTHLY' : 'YEAR',
            quantity: counter,
            version: subscriptionVersion,
            currency,
        }
        const amount = await dispatch(getAmount(data));
        dispatch(setPricePerUser(Math.trunc((amount.payload.price / amount.payload.full_quantity) * 100) / 100));
        dispatch(setSubscriptionType(newSubscriptionType));
    };
    const handleChangeCurrency = async (event, newCurrency) => {
        if (!newCurrency) return;
        const data = {
            period: subscriptionType === 'monthly' ? 'MONTHLY' : 'YEAR',
            quantity: counter,
            version: subscriptionVersion,
            currency: newCurrency,
        }
        const amount = await dispatch(getAmount(data));
        dispatch(setPricePerUser(Math.trunc((amount.payload.price / amount.payload.full_quantity) * 100) / 100));
        dispatch(setCurrency(newCurrency));
    };
    const handleClickProcessSubscription = async () => {
        if (SUBSCRIPTION_OVER || TRIAL) {
            handleBuy(counter, subscriptionType, user.user.email, currency);
            return;
        }
        await store.dispatch(calculateAmountChanges({
            quantity: counter,
            period: subscriptionType === 'monthly' ? 'MONTHLY' : 'YEAR'
        }));
        store.dispatch(openReviewAndCheckoutDialog(true));
    };
    const handleBuy = (quantity, subscriptionType, email, currency) => {
        const Paddle = window.Paddle;
        const type = subscriptionType === 'annually' ? 'YEAR' : 'MONTHLY';
        const paymentLinkDataPromise = getPayLink(quantity, type, currency);
        paymentLinkDataPromise.then(resp => {
            const paymentLinkData = resp.data;
            // Paddle.Checkout.open({
            //     override: paymentLinkData.url,
            //     email,
            //     successCallback: paddleSuccessCallback
            // })
            Paddle.Checkout.open({
                transactionId: paymentLinkData.transaction_id
            })
        }).catch((error) => {
            let message = 'An error occurred';
            if (error.response?.data?.code === 403) {
                message = 'Wrong quantity';
            }
            dispatch(
                showNotification({
                    type: 'error',
                    message,
                })
            );
        });
    }
    const handleConfirm = async () => {
        store.dispatch(openReviewAndCheckoutDialog(false));
        if (subscription.quantity !== counter) {
            await dispatch(subscriptionChangeLicensesCount({
                subscriptionId: subscription.subscription_id,
                newCount: counter
            }));
        }
        if (subscription.plan_id === 2 && subscriptionType === 'annually') {
            await dispatch(subscriptionChangeLicensesToAnnual({
                subscriptionId: subscription.subscription_id
            }));
        }
    };
    const getPayLink = (quantity, type, currency) => {
        const basename = process.env.REACT_APP_API_URL || 'https://app2.sharedcontactsmanager.com/';
        const url = new URL('/get_pay_transaction', basename);
        const data = {quantity, type, currency};
        return axios.post(url.href, data, {
            withCredentials: true,
        });
    }

    const paddleSuccessCallback = data => {
        const quantity = counter;
        const type = subscriptionType === 'annually' ? 'YEAR' : 'MONTHLY';
        const checkout = data.checkout || {};
        const checkoutId = checkout.id || 'none';
        const prices = checkout.prices || {};
        const vendor = prices.vendor || {};
        const total = vendor.total || 0;
        const totalTax = vendor.total_tax || 0;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'paymentComplete',
            scm_quantity: quantity,
            scm_plan_type: type,
            scm_total: total,
            scm_total_tax: totalTax,
            checkout_id: checkoutId,
        })
        if (window.mixpanel) {
            window.mixpanel.track('paymentComplete', {
                scm_quantity: quantity,
                scm_plan_type: type,
                scm_total: total,
                scm_total_tax: totalTax,
                checkout_id: checkoutId,
            })
        }
    }

    const CurrencyTab = () => {
        if (currency === 'USD' && !(SUBSCRIPTION_OVER || TRIAL)) {
            return <ToggleButtonGroup
                disabled
                color="primary"
                value={currency}
                exclusive
                onChange={handleChangeCurrency}
                aria-label="currency_usd"
            >
                <ToggleButton
                    sx={{
                        width: 71,
                        textTransform: "none",
                        borderColor: 'rgba(59, 125, 237, 0.50)',
                    }}
                    size={'small'} value="usd">
                    <Typography
                        variant={'button'}
                        sx={{
                            textTransform: "none",
                            color: 'rgba(0, 0, 0, .38)',
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: "Roboto",
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontSize: 14,
                            lineHeight: '24px',
                            letterSpacing: '0.46px',
                        }}
                    >
                        USD $
                    </Typography>
                </ToggleButton>
            </ToggleButtonGroup>
        }
        if (currency === 'EUR' && !(SUBSCRIPTION_OVER || TRIAL)) {
            return <ToggleButtonGroup
                disabled
                color="primary"
                value={currency}
                exclusive
                onChange={handleChangeCurrency}
                aria-label="currency_eur"
            >
                <ToggleButton
                    sx={{
                        width: 71,
                        textTransform: "none",
                        borderColor: 'rgba(59, 125, 237, 0.50)',
                    }}
                    size={'small'} value="eur">
                    <Typography
                        variant={'button'}
                        sx={{
                            textTransform: "none",
                            color: 'rgba(0, 0, 0, .38)',
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: "Roboto",
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontSize: 14,
                            lineHeight: '24px',
                            letterSpacing: '0.46px',
                        }}
                    >
                        EUR €
                    </Typography>
                </ToggleButton>
            </ToggleButtonGroup>
        }
        return <ToggleButtonGroup
            color="primary"
            value={currency}
            exclusive
            onChange={handleChangeCurrency}
            aria-label="currency"
        >
            <ToggleButton
                sx={{
                    width: 71,
                    textTransform: "none",
                    borderColor: 'rgba(59, 125, 237, 0.50)',
                    "&.Mui-selected, &.Mui-selected:hover": {
                        color: "rgba(59, 125, 237, 0.12)",
                        background: 'rgba(59, 125, 237, 0.12)'
                    }
                }}
                size={'small'} value="EUR">
                <Typography
                    variant={'button'}
                    sx={{
                        textTransform: "none",
                        color: currency === 'EUR' ? 'rgba(59, 125, 237, 1)' : 'rgba(0, 0, 0, 0.6)',
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "Roboto",
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: 14,
                        lineHeight: '24px',
                        letterSpacing: '0.46px',
                    }}
                >
                    EUR €
                </Typography>
            </ToggleButton>
            <ToggleButton
                sx={{
                    width: 72,
                    textTransform: "none",
                    borderColor: 'rgba(59, 125, 237, 0.50)',
                    "&.Mui-selected, &.Mui-selected:hover": {
                        color: "rgba(59, 125, 237, 0.12)",
                        background: 'rgba(59, 125, 237, 0.12)'
                    }
                }}
                size={'small'} value="USD">
                <Typography
                    variant={'button'}
                    sx={{
                        textTransform: "none",
                        color: currency === 'USD' ? 'rgba(59, 125, 237, 1)' : 'rgba(0, 0, 0, 0.6)',
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "Roboto",
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: 14,
                        lineHeight: '24px',
                        letterSpacing: '0.46px',
                    }}
                >
                    USD $
                </Typography>
            </ToggleButton>
        </ToggleButtonGroup>
    }

    const PeriodTab = () => {
        const billingCycles = [
            {value: "monthly", label: "Monthly"},
            {value: "annually", label: "Annual (Save 30%)"},
        ];
        const isDisabled = () => !((subscription.plan_id !== 1 && subscription.status === 0) || subscription.plan_id === 1 || subscription.plan_id === 3);
        if (SUBSCRIPTION_OVER || TRIAL) {
            return (
                <ToggleButtonGroup
                    disabled={isDisabled()}
                    color="primary"
                    value={subscriptionType}
                    exclusive
                    onChange={handleChangeBillingCycle}
                    aria-label="billing_cycle"
                >
                    {billingCycles.map((cycle) => (
                        <ToggleButton
                            key={cycle.value}
                            sx={{
                                width: 156,
                                textTransform: "none",
                                borderColor: 'rgba(59, 125, 237, 0.50)',
                                "&.Mui-selected, &.Mui-selected:hover": {
                                    color: "rgba(59, 125, 237, 0.12)",
                                    background: 'rgba(59, 125, 237, 0.12)'
                                }
                            }}
                            size={'small'}
                            value={cycle.value}
                        >
                            <Typography
                                variant={'button'}
                                sx={{
                                    textTransform: "none",
                                    color: subscriptionType === cycle.value ? 'rgba(59, 125, 237, 1)' : 'rgba(0, 0, 0, 0.6)',
                                    fontFeatureSettings: "'clig' off, 'liga' off",
                                    fontFamily: "Roboto",
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    fontSize: 14,
                                    lineHeight: '24px',
                                    letterSpacing: '0.46px',
                                }}
                            >
                                {cycle.label}
                            </Typography>
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            );
        }
        return (
            <ToggleButtonGroup
                disabled
                color="primary"
                exclusive
                onChange={handleChangeBillingCycle}
                aria-label="billing_cycle"
            >
                <ToggleButton
                    key={subscriptionType}
                    sx={{
                        width: 156,
                        textTransform: "none",
                        borderColor: 'rgba(59, 125, 237, 0.50)',
                    }}
                    size={'small'}
                    value={subscriptionType}
                >
                    <Typography
                        variant={'button'}
                        sx={{
                            textTransform: "none",
                            color: 'rgba(0, 0, 0, .38)',
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: "Roboto",
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontSize: 14,
                            lineHeight: '24px',
                            letterSpacing: '0.46px',
                        }}
                    >
                        {subscriptionType === "monthly" ? "Monthly" : 'Annual'}
                    </Typography>
                </ToggleButton>
            </ToggleButtonGroup>
        );
    }
    const Tabs = () => {
        return <Stack direction={'row'} sx={{marginTop: 4, width: 496}}>
            <PeriodTab/>
            <Box sx={{flexGrow: 1}}/>
            <CurrencyTab/>
        </Stack>
    }
    const ChangeLicenseCountPanel = () => {
        return <Box
            sx={{
                width: 496,
                height: 372,
                display: 'inline-flex',
                padding: '24px 40px',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '32px',
                borderRadius: '6px',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                background: 'rgba(255, 255, 255, 0.08)',
                marginTop: 3,
            }}
        >
            <Stack
                spacing={4}
                sx={{
                    width: '100%',
                    height: '100%',
                }}
            >
                <Stack spacing={2} sx={{alignItems: 'center'}}>
                    <Typography
                        variant={"body1"}
                        sx={{
                            color: 'rgba(0, 0, 0, 0.6)',
                            fontFamily: "Roboto",
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: 16,
                            lineHeight: '150%',
                            letterSpacing: '0.15px',
                            fontFeatureSettings: "'clig' off, 'liga' off",
                        }}>
                        How many licenses do you need?
                    </Typography>
                    <Stack spacing={1} sx={{alignItems: 'center'}}>
                        <QuantityLicenses error={error} setError={setError}/>
                        <Typography
                            sx={{
                                color: 'rgba(0, 0, 0, 0.54)',
                                fontFamily: "Open Sans",
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 14,
                                lineHeight: '20px',
                                letterSpacing: '-0.28px',
                                fontFeatureSettings: "'clig' off, 'liga' off",
                            }}>
                            {currencySign}{pricePerUser} per
                            user/{subscriptionType === 'monthly' ? 'month' : 'year'}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack spacing={1} sx={{alignItems: 'center'}}>
                    <Typography
                        sx={{
                            color: 'rgba(0, 0, 0, 0.6)',
                            fontFamily: "Roboto",
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: 16,
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                            fontFeatureSettings: "'clig' off, 'liga' off",
                        }}>
                        Total price
                    </Typography>
                    <Stack direction="row" spacing={0} sx={{alignItems: 'baseline'}}>
                        <Typography
                            sx={{
                                color: 'rgba(0, 0, 0, 0.87)',
                                fontFamily: "Open Sans",
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: 36,
                                lineHeight: '44px',
                                letterSpacing: '-1.44px',
                                fontFeatureSettings: "'clig' off, 'liga' off",
                            }}>
                            <span>{currencySign}</span>
                        </Typography>
                        <Typography
                            sx={{
                                color: 'rgba(0, 0, 0, 0.87)',
                                fontFamily: "Open Sans",
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: 36,
                                lineHeight: '44px',
                                letterSpacing: '-1.44px',
                                fontFeatureSettings: "'clig' off, 'liga' off",
                            }}>
                            <span>{amount.price && amount.price.toLocaleString() || 0}</span>
                        </Typography>
                        <Typography
                            sx={{
                                color: 'rgba(0, 0, 0, 0.87)',
                                fontFamily: "Open Sans",
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 14,
                                lineHeight: '20px',
                                letterSpacing: '-0.56px',
                                fontFeatureSettings: "'clig' off, 'liga' off",
                                marginLeft: '2px'
                            }}>
                            /{subscriptionType === 'monthly' ? 'month' : 'year'}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack spacing={'12px'} sx={{alignItems: 'center'}}>
                    {subscription.status !== 2 &&
                    <LoadingButton
                        // disabled={subscription.status !== 0 && subscription.quantity === counter || subscription.plan_id === 2 && subscriptionType !== 'annually'}
                        disabled={disableUpdateButton()}
                        type="submit"
                        loading={updateSubscriptionStatus === 'loading'}
                        variant='contained'
                        sx={{
                            width: 416,
                            height: 42,
                            // backgroundColor: 'rgba(59, 125, 237, 1)',
                        }}
                        onClick={handleClickProcessSubscription}
                    >
                        <Typography variant={'button'}
                                    sx={{
                                        textTransform: "none",
                                        color: 'rgba(255, 255, 255, 1)',
                                        fontFamily: "Roboto",
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        fontSize: 15,
                                        lineHeight: '26px',
                                        letterSpacing: '0.46px',
                                        fontFeatureSettings: "'clig' off, 'liga' off"
                                    }}>
                            {SUBSCRIPTION_OVER || TRIAL ? 'Continue to payment details' : 'Continue with subscription change'}
                        </Typography>
                    </LoadingButton>
                    }
                    {/* <Stack direction={'row'}> */}
                    {/* <Typography */}
                    {/*    sx={{ */}
                    {/*        color: 'rgba(0, 0, 0, 0.54)', */}
                    {/*        fontFamily: "Open Sans", */}
                    {/*        fontStyle: 'normal', */}
                    {/*        fontWeight: 400, */}
                    {/*        fontSize: 14, */}
                    {/*        lineHeight: '20px', */}
                    {/*        letterSpacing: '-0.28px', */}
                    {/*        fontFeatureSettings: "'clig' off, 'liga' off", */}
                    {/*    }} */}
                    {/* > */}
                    {/*    <span>{currencySign}</span> */}
                    {/* </Typography> */}
                    {/* <Typography */}
                    {/*    sx={{ */}
                    {/*        color: 'rgba(0, 0, 0, 0.54)', */}
                    {/*        fontFamily: "Open Sans", */}
                    {/*        fontStyle: 'normal', */}
                    {/*        fontWeight: 400, */}
                    {/*        fontSize: 14, */}
                    {/*        lineHeight: '20px', */}
                    {/*        letterSpacing: '-0.28px', */}
                    {/*        fontFeatureSettings: "'clig' off, 'liga' off", */}
                    {/*    }} */}
                    {/* > */}
                    {/*    <span>{amount.price && amount.price.toLocaleString() || 0}</span> billed {subscriptionType === 'monthly' ? 'monthly' : 'annually'} */}
                    {/*    <span>{subscriptionType === 'annually' && `(save ${currencySign}${amount.save && amount.save.toLocaleString()})`}</span> */}
                    {/* </Typography> */}
                    {/* </Stack> */}
                </Stack>
            </Stack>
        </Box>
    }
    return (
        <>
            <Grid container spacing={0}>
                <Grid item md={2}>
                    <Stack
                        spacing={1}
                        sx={{
                            width: 215,
                            height: 56,
                            marginTop: '25px',
                            marginLeft: '26px',
                        }}
                    >
                        <Stack direction={"row"} spacing={0.5}>
                            <Typography
                                variant={"body1"}
                                sx={{
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    fontFamily: "Roboto",
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: 16,
                                    lineHeight: '24px',
                                    letterSpacing: '0.15px',
                                }}>
                                Current licenses:
                            </Typography>
                            <Typography
                                variant={"body1"}
                                sx={{
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    fontFamily: "Roboto",
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: 16,
                                    lineHeight: '24px',
                                    letterSpacing: '0.15px',
                                }}>
                                {subscription.quantity || 0}
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={0.5}>
                            <Typography
                                variant={"body1"}
                                sx={{
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    fontFamily: "Roboto",
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: 16,
                                    lineHeight: '24px',
                                    letterSpacing: '0.15px',
                                }}>
                                Current billing cycle:
                            </Typography>
                            <Typography
                                variant={"body1"}
                                sx={{
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    fontFamily: "Roboto",
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: 16,
                                    lineHeight: '24px',
                                    letterSpacing: '0.15px',
                                }}>
                                {getBillingPeriod(subscription)}
                            </Typography>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item md={8}>
                    <Stack spacing={0} display='flex' alignItems='center' justifyContent='center'
                           sx={{marginTop: '39px'}}>
                        <Typography
                            sx={{
                                color: 'rgba(60, 64, 67, 1)',
                                fontFamily: "Open Sans",
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: 28,
                                lineHeight: '40px',
                                letterSpacing: '-0.56px',
                            }}>
                            Change license count
                        </Typography>
                        <Tabs/>
                        <ChangeLicenseCountPanel/>
                        {error && <Stack
                            direction={"row"}
                            spacing={'12px'}
                            display='flex'
                            alignItems='center'
                            sx={{
                                width: 496,
                                height: 48,
                                padding: '6px 16px',
                                borderRadius: '4px',
                                background: '#FBEAEA',
                                marginTop: 2,
                            }}
                        >
                            <ErrorOutline sx={{color: 'rgba(211, 47, 47, 1)', width: 22, height: 22}}/>
                            <Typography
                                sx={{
                                    color: 'rgba(84, 19, 19, 1)',
                                    fontFamily: "Roboto",
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: 14,
                                    lineHeight: '143%',
                                    letterSpacing: '0.17px',
                                    fontFeatureSettings: "'clig' off, 'liga' off",
                                }}
                            >
                                You are now sharing labels with {subscription.connected_users.length} people. Remove
                                users in order to buy fewer licenses.
                            </Typography>
                        </Stack>}
                    </Stack>
                </Grid>
                <Grid item md={2}/>
            </Grid>
            <ReviewAndCheckoutDialog counter={counter} handleConfirm={handleConfirm}/>
        </>
    );
}
