import * as React from "react";
import { Box, Divider, Typography, Toolbar, Grid, SvgIcon, Stack, LinearProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { PeopleAltOutlined, TaskOutlined } from "@mui/icons-material";

// project import
import Header from "../components/Header/Header";
import Users from "../components/ManageUsers/Users";
import ManageUsersAppBar from "../components/ManageUsers/ManageUsersAppBar";
import UsersBreakdown from "../components/ManageUsers/UsersBreakdown";
import AddUserToSubscriptionDialog from "../components/ManageUsers/AddUserToSubscriptionDialog";
import AlertRemoveLicenseDialog from "../components/ManageUsers/AlertRemoveLicenseDialog";
import AlertDeleteUserDialog from "../components/ManageUsers/AlertDeleteUserDialog";
import RemoveAdminPermissionsDialog from "../components/ManageUsers/AlertRemoveAdminPermissionsDialog";
import AlertAddAdminPermissionsDialog from "../components/ManageUsers/AlertAddAdminPermissionsDialog";
import AlertAddUserToSubscriptionDialog from "../components/ManageUsers/AlertAddUserToSubscriptionDialog";
import RestrictedPage from "../components/RestrictedPage";
import CustomBreadcrumbs from "../components/CustomBreadcrumbs";
import { ALERT_HEIGHT, HEADER_HEIGHT, BREADCRUMBS_HEIGHT, MANAGE_USERS_APPBAR_HEIGHT, MANAGE_USERS_HEADER_HEIGHT, USERS_BREAKDOWN_HEIGHT } from "../constants/size";
import AlertsBar from "../components/Alerts/AlertsBar";

export default function ManageUsersPage() {
    return (
        <>
            <Header />
            <AlertsBar />
            <CustomBreadcrumbs />
            <ManageUsersContent />
            <RestrictedPage />
            <AddUserToSubscriptionDialog />
            <AlertRemoveLicenseDialog />
            <AlertDeleteUserDialog />
            <RemoveAdminPermissionsDialog />
            <AlertAddAdminPermissionsDialog />
            <AlertAddUserToSubscriptionDialog />
        </>
    )
}

const ManageUsersContent = () => {
    const subscription = useSelector((state) => state.user.subscription);
    const subscriptionInitialized = useSelector((state) => state.user.subscriptionInitialized);
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    const noLicenseAlert = useSelector((state) => state.user.noLicenseAlert);
    const showAlert = domainInstallAlert || noLicenseAlert;
    if (!subscriptionInitialized || subscription.status === 2) return null;
    const topOffset = showAlert ? HEADER_HEIGHT + ALERT_HEIGHT : HEADER_HEIGHT; // Calculate the total height of Header + Alert if Alert is displayed
    const topOffsetLocal = showAlert ? 24 + USERS_BREAKDOWN_HEIGHT + 8 + MANAGE_USERS_APPBAR_HEIGHT + 1 + ALERT_HEIGHT :
        24 + USERS_BREAKDOWN_HEIGHT + 8 + MANAGE_USERS_APPBAR_HEIGHT + 1;
    return (
        <Box component="main" sx={{
            marginTop: `${topOffset}px`,

        }}>
            <ManageUsersHeader />
            <Divider />
            <Box
                component="main"
                sx={{
                    px: 4,
                    height: `calc(100vh - ${topOffsetLocal}px)`,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <UsersBreakdown />
                <ManageUsersAppBar />
                <Divider />
                <Box sx={{ flexGrow: 1, overflow: 'hidden', width: '100%', marginTop: 2 }}>
                    <Users />
                </Box>
            </Box>
        </Box>
    )
}

const ManageUsersHeader = () => {
    return <Toolbar sx={{ height: 64 }}>
        <Typography sx={{
            color: 'rgba(60, 64, 67, 1)',
            fontSize: 28,
            fontFamily: "Open Sans",
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '36px',
            letterSpacing: '-0.02em',
        }}>
            Manage Users
        </Typography>
    </Toolbar>;
}