import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from "react-redux";
import { Grid, IconButton, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";

// project import
import store from "../../store/store";
import { openAlertDeleteLicenseDialog } from "../../store/reducers/dialogSlice";
import {
    getSubscriptionData,
    removeUsersFromSubscription,
    setLicensesToDelete,
    setSelectedUsers,
} from "../../store/reducers/userSlice";
import { showNotification } from "../../store/reducers/notificationSlice";
import { removeSharedUsersFromGroups } from "../../store/reducers/groupsSlice";

export default function AlertRemoveLicenseDialog() {
    const open = useSelector((state) => state.dialogsOpen.alertDeleteLicense.value);
    const licensesToDelete = useSelector((state) => state.user.licensesToDelete);
    const removeUsersFromSubscriptionStatus = useSelector((state) => state.user.removeUsersFromSubscriptionStatus);
    const loading = removeUsersFromSubscriptionStatus === 'loading';
    const handleClose = () => {
        store.dispatch(openAlertDeleteLicenseDialog(false));
    };
    const handleDeleteLicenses = async () => {
        // To prevent removing licenses from users that don't have a userId or user_id
        const usersIds = licensesToDelete.reduce((acc, user) => {
            if (user && (user.userId || user.user_id)) {
                acc.push(user.userId || user.user_id);
            }
            return acc;
        }, []);
        await store.dispatch(removeUsersFromSubscription(usersIds));
        store.dispatch(removeSharedUsersFromGroups({ usersIds }));
        store.dispatch(getSubscriptionData());
        handleClose();
        store.dispatch(
            showNotification({
                type: 'success',
                message: `License${licensesToDelete?.length > 1 ? 's' : ''} removed`,
            })
        );
        store.dispatch(setLicensesToDelete([]));
        store.dispatch(setSelectedUsers([]));
    };
    const hasAdmin = licensesToDelete.some(item => item.isAdmin);
    const plural = licensesToDelete.length > 1 || licensesToDelete.length === 0;
    const userText = plural ? 'users' : 'user';
    const message = hasAdmin
        ? 'Licenses cannot be removed from admin users.'
        : `You're about to remove license${plural ? 's' : ''} from the selected ${userText}. This will revoke their access to shared contacts and prevent them from sharing contacts.`;

    const CancelButton = () => {
        if (hasAdmin) {
            return null;
        }
        return (
            <Button onClick={handleClose}>
                <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Cancel</Typography>
            </Button>
        );
    };
    const CloseButton = () => {
        if (!hasAdmin) {
            return null;
        }
        return (
            <Button onClick={handleClose}>
                <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Close</Typography>
            </Button>
        );
    };
    const ConfirmButton = () => {
        if (hasAdmin) {
            return null;
        }
        return (
            <LoadingButton
                loading={loading}
                disabled={loading}
                onClick={handleDeleteLicenses}
            >
                <Typography variant={'dialogButton'} sx={{
                    color: loading ? 'rgba(0, 0, 0, 0.32)' : 'rgba(59, 125, 237, 1)'
                }}>Confirm</Typography>
            </LoadingButton>
        );
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-delete-licenses-dialog-title"
            aria-describedby="alert-delete-licenses-dialog-description"
            PaperProps={{
                sx: {
                    minWidth: 444,
                    backgroundColor: 'rgba(59, 125, 237, 1)'
                }
            }}
        >
            <DialogTitle sx={{ backgroundColor: 'rgba(59, 125, 237, 1)' }}>
                <Grid container sx={{ alignItems: 'center' }}>
                    <Grid item>
                        <Typography
                            variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Warning</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: 'rgba(255, 255, 255, 0.54)',
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        backgroundColor: 'rgba(0, 0, 0, 0.16)'
                    },
                }}
            >
                <CloseOutlined />
            </IconButton>
            <DialogContent dividers sx={{ border: 0, backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                <Typography
                    sx={{
                        color: 'rgba(0, 0, 0, 0.87)',
                        fontSize: 16,
                        fontFamily: `"Roboto"`,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.2px',
                    }}
                >
                    {message}
                </Typography>
            </DialogContent>
            <DialogActions sx={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                <CancelButton />
                <CloseButton />
                <ConfirmButton />
            </DialogActions>
        </Dialog>
    );
}

