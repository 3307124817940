// Defining constants for error reasons
const ERROR_REASONS = {
    NO_SEATS: "NO_SEATS",
    RESTRICTED_BY_ADMIN: "RESTRICTED_BY_ADMIN",
    EMAIL_VALIDATION_ERROR: "EMAIL_VALIDATION_ERROR",
};

// Defining corresponding messages for errors
const ERROR_MESSAGES = {
    [ERROR_REASONS.NO_SEATS]: 'There are not enough unassigned licenses. Please purchase additional licenses.',
    [ERROR_REASONS.RESTRICTED_BY_ADMIN]: 'Some emails are restricted by admin.',
    [ERROR_REASONS.EMAIL_VALIDATION_ERROR]: 'Some of the provided emails are unreachable.',
};

// Default message
const DEFAULT_MESSAGE = "Something went wrong, please contact us.";

// Error priority (from most important to least important)
const ERROR_PRIORITY = [
    ERROR_REASONS.NO_SEATS,
    ERROR_REASONS.RESTRICTED_BY_ADMIN,
    ERROR_REASONS.EMAIL_VALIDATION_ERROR,
];

/**
 * Function to get error message based on rejectInfo array
 * @param {Array} rejectInfoArray - Array of objects with error information
 * @returns {string} - Error message
 */
export default function getErrorMessage(rejectInfoArray) {
    const foundErrors = rejectInfoArray.reduce((acc, item) => {
        const reason = item.reason;
        if (ERROR_MESSAGES[reason]) {
            acc.add(reason);
        }
        return acc;
    }, new Set());

    // Find first error according to priority
    const prioritizedError = ERROR_PRIORITY.find(reason => foundErrors.has(reason));

    return prioritizedError ? ERROR_MESSAGES[prioritizedError] : DEFAULT_MESSAGE;
}
