import { Box, Button, Stack, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { CalendarTodayOutlined, CreditCardOutlined } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { openMeetingDialog } from "../../store/reducers/dialogSlice";
import { isNotTrial } from "../../services/subscriptionStatus";

export default function SubscriptionStatusAlert({ setRef }) {
    const navigate = useNavigate();
    const subscription = useSelector((state) => state.user.subscription);
    const noLicenseAlert = useSelector((state) => state.user.noLicenseAlert);
    const dispatch = useDispatch();

    if (isNotTrial(subscription) || noLicenseAlert) return null;
    const isTrialActivated = subscription.trail_activated;
    const remainingDaysMessage = () => {
        switch (subscription.remaining_days) {
            case 1:
                return "today";
            case 2:
                return "tomorrow";
            default:
                return `${subscription.remaining_days} days`;
        }
    };
    const message = isTrialActivated
        ? (
            <>Free trial expires {subscription.remaining_days > 2 && 'in'} <Typography component={'span'} sx={typographyStyles}>{remainingDaysMessage()}</Typography></>
        ) : 'Need help with the app?';
    return (
        <Box ref={setRef} sx={boxStyles(isTrialActivated)}>
            <Box sx={innerBoxStyles(isTrialActivated)}>
                <Stack spacing={2}>
                    <Typography sx={typographyStyles}>{message}</Typography>
                    <Stack spacing={2}>
                        {isTrialActivated && (
                            <ActionButton
                                label="Add payment details"
                                icon={<CreditCardOutlined sx={iconStyles} />}
                                onClick={() => navigate("/manage_subscription")}
                            />
                        )}
                        <ActionButton
                            label="Book a demo"
                            icon={<CalendarTodayOutlined sx={iconStyles} />}
                            onClick={() => dispatch(openMeetingDialog(true))}
                        />
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
}
const ActionButton = ({ label, icon, onClick }) => (
    <Button
        variant="outlined"
        sx={buttonStyles}
        startIcon={icon}
        onClick={onClick}
    >
        <Typography variant="button" sx={buttonTypographyStyles}>
            {label}
        </Typography>
    </Button>
);
// Styles
const typographyStyles = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "0.17px",
    color: "#013654",
};
const boxStyles = (isTrialActivated) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "20px",
    gap: "10px",
    position: "absolute",
    width: "260px",
    height: isTrialActivated ? 180 : 132,
    left: "0px",
    bottom: "0px",
});
const innerBoxStyles = (isTrialActivated) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "12px",
    gap: "12px",
    width: "220px",
    height: isTrialActivated ? 140 : 92,
    background: "#E6F3FA",
    borderRadius: "4px",
});
const buttonStyles = {
    width: 196,
    height: 36,
    borderColor: "rgba(1, 54, 84, 0.24)",
    color: "rgba(1, 54, 84, 0.24)",
    "&:hover": {
        borderColor: "rgba(1, 54, 84, 0.24)",
        backgroundColor: "rgba(1, 54, 84, 0.1)",
    },
};
const buttonTypographyStyles = {
    textTransform: "none",
    letterSpacing: 0,
    fontSize: 13,
    color: "rgba(1, 54, 84, 1)",
};
const iconStyles = {
    width: 18,
    height: 18,
    color: "rgba(1, 54, 84, 1)",
};
