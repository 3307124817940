import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from "react-redux";
import { Grid, IconButton, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import LoadingButton from '@mui/lab/LoadingButton';

// project import
import store from "../../store/store";
import { openAlertAddUserToSubscriptionDialog } from "../../store/reducers/dialogSlice";
import { subscriptionAddUsers, setUsersToAddToSubscription, setSelectedUsers, getSubscriptionData } from "../../store/reducers/userSlice";
import { showNotification } from "../../store/reducers/notificationSlice";

export default function AlertAddUserToSubscriptionDialog() {
    const open = useSelector((state) => state.dialogsOpen.alertAddUserToSubscription.value);
    const subscription = useSelector((state) => state.user.subscription);
    const usersToAddToSubscription = useSelector((state) => state.user.usersToAddToSubscription);
    const subscriptionAddUsersStatus = useSelector((state) => state.user.subscriptionAddUsersStatus);
    if (!open) {
        return null;
    }
    const loading = subscriptionAddUsersStatus === 'loading';
    const handleClose = () => {
        store.dispatch(openAlertAddUserToSubscriptionDialog(false));
    };
    const handleAddUsers = async () => {
        await store.dispatch(subscriptionAddUsers(usersToAddToSubscription.map(user => user.email)));
        handleClose();
        store.dispatch(getSubscriptionData());
        store.dispatch(setUsersToAddToSubscription([]));
        store.dispatch(setSelectedUsers([]));
    };
    const unlicensedUsersAmount = usersToAddToSubscription.filter(user => user.license === 'Unlicensed').length;
    const freeLicenses = subscription.quantity - subscription.active_quantity;
    const noAvailableLicenses = unlicensedUsersAmount > freeLicenses;
    const buyMoreLicenses = noAvailableLicenses ? unlicensedUsersAmount - freeLicenses : 0;
    const message = noAvailableLicenses ?
        `You have ${freeLicenses} available license${freeLicenses > 1 || freeLicenses === 0 ? 's' : ''} 
     but are trying to assign them to ${unlicensedUsersAmount} unlicensed user${unlicensedUsersAmount > 1 ? 's' : ''}. 
     Please buy ${buyMoreLicenses} more license${buyMoreLicenses > 1 ? 's' : ''} to proceed.` :
        unlicensedUsersAmount === 0 ? "You didn't select any unlicensed users to add licenses to." : `You're about to add license${unlicensedUsersAmount > 1 || unlicensedUsersAmount === 0 ? 's' : ''} to the selected user${unlicensedUsersAmount > 1 || unlicensedUsersAmount === 0 ? 's' : ''}.`;
    const CancelButton = () => {
        if (noAvailableLicenses || unlicensedUsersAmount === 0) {
            return null;
        }
        return (
            <Button onClick={handleClose}>
                <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Cancel</Typography>
            </Button>
        );
    };
    const CloseButton = () => {
        if (!noAvailableLicenses && unlicensedUsersAmount !== 0) {
            return null;
        }
        return (
            <Button onClick={handleClose}>
                <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Close</Typography>
            </Button>
        );
    };
    const ConfirmButton = () => {
        if (noAvailableLicenses || unlicensedUsersAmount === 0) {
            return null;
        }
        return (
            <LoadingButton
                loading={loading}
                disabled={loading}
                onClick={handleAddUsers}
            >
                <Typography variant={'dialogButton'} sx={{
                    color: loading ? 'rgba(0, 0, 0, 0.32)' : 'rgba(59, 125, 237, 1)'
                }}>Confirm</Typography>
            </LoadingButton>
        );
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    minWidth: 444,
                    backgroundColor: 'rgba(59, 125, 237, 1)'
                }
            }}
        >
            <DialogTitle sx={{ backgroundColor: 'rgba(59, 125, 237, 1)' }}>
                <Grid container sx={{ alignItems: 'center' }}>
                    <Grid item>
                        <Typography
                            variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Warning</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: 'rgba(255, 255, 255, 0.54)',
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        backgroundColor: 'rgba(0, 0, 0, 0.16)'
                    },
                }}
            >
                <CloseOutlined />
            </IconButton>
            <DialogContent dividers sx={{ border: 0, backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                <Typography
                    sx={{
                        color: 'rgba(0, 0, 0, 0.87)',
                        fontSize: 16,
                        fontFamily: `"Roboto"`,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '0.2px',
                    }}
                >
                    {message}
                </Typography>
            </DialogContent>
            <DialogActions sx={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                <CancelButton />
                <CloseButton />
                <ConfirmButton />
            </DialogActions>
        </Dialog>
    );
}

