import * as React from "react";
import { Box, Divider, Toolbar, Typography } from "@mui/material";
import { useSelector } from "react-redux";

// project import
import Header from "../components/Header/Header";
import { ChangeLicenseCount } from "../components/ManageSubscription/ChangeLicenseCount";
import RestrictedPage from "../components/RestrictedPage";
import { ALERT_HEIGHT, HEADER_HEIGHT } from "../constants/size";
import AlertsBar from "../components/Alerts/AlertsBar";
import CustomBreadcrumbs from "../components/CustomBreadcrumbs";

export default function ManageSubscriptionPage() {
    const subscription = useSelector((state) => state.user.subscription);
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    const noLicenseAlert = useSelector((state) => state.user.noLicenseAlert);
    const showAlert = domainInstallAlert || noLicenseAlert;
    const topOffset = showAlert ? HEADER_HEIGHT + ALERT_HEIGHT : HEADER_HEIGHT; // Calculate the total height of Header + Alert if Alert is displayed

    return (
        <>
            <Header />
            <AlertsBar />
            <CustomBreadcrumbs />
            <Box component="main" sx={{
                flexGrow: 1,
                marginTop: `${topOffset}px`,
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Toolbar sx={{ height: 72 }}>
                    <Typography
                        sx={{
                            color: 'rgba(60, 64, 67, 1)',
                            fontSize: 28,
                            fontFamily: "Open Sans",
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '36px',
                            letterSpacing: '-0.2px',
                        }}
                    >
                        Change subscription
                    </Typography>
                </Toolbar>
                <Divider />
                {subscription.status !== 2 ? <ChangeLicenseCount /> : <RestrictedPage />}
            </Box>
        </>
    )
}
