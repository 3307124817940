// Constants for sizes
import ManageUsersAppBar from "../components/ManageUsers/ManageUsersAppBar";

export const DRAWER_WIDTH = 260; // Sidebar width (Drawer)
export const HEADER_HEIGHT = 64; // Top Header height
export const ALERT_HEIGHT = 48; // Alert height
export const TOOLBAR_HEIGHT = 64; // Height of Toolbar above table
export const DATA_GRID_HEADER_HEIGHT = 50; // Height of DataGrid header
export const ROW_HEIGHT = 72; // Height of one row in DataGrid
export const BUFFER = 10; // Additional buffer to prevent scrollbars
export const BREADCRUMBS_HEIGHT = 64; // Height of Breadcrumbs
export const MANAGE_USERS_APPBAR_HEIGHT = 64; // Height of ManageUsersAppBar
export const MANAGE_USERS_HEADER_HEIGHT = 64; // Height of ManageUsersHeader
export const USERS_BREAKDOWN_HEIGHT = 96; // Height of UsersBreakdown
export const CHANGE_SUBSCRIPTION_TOOLBAR_HEIGHT = 64; // Height of Change subscription Toolbar

