import * as React from "react";
import {Toolbar} from "@mui/material";
import {useSelector} from "react-redux";

// project import
import Header from "../components/Header/Header";
import TrialSubscriptionOver from "../components/Trial/TrialSubscriptionOver";

export default function TrialPage() {
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    const noLicenseAlert = useSelector((state) => state.user.noLicenseAlert);
    const isAlertBar = domainInstallAlert || noLicenseAlert;
    return (
        <>
            <Header/>
            <Toolbar/>
            {isAlertBar && <Toolbar variant="dense"/>}
            <TrialSubscriptionOver/>
        </>
    )
}
