import * as React from 'react';
import {
    Badge,
    Box, Chip,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Stack,
    SvgIcon,
    Tooltip,
    Typography,
    AvatarGroup, Paper, MenuList,
    Menu,
} from "@mui/material";
import {
    AccessTimeFilledOutlined,
    Cancel,
    CheckCircle,
    Clear,
    DeleteOutlineOutlined,
    ErrorOutlined,
    HelpOutlined,
    PauseCircleFilledOutlined,
    RemoveCircleOutlined,
    MoreVertOutlined,
    BookmarkAddOutlined, StarOutlined,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useMemo, useState } from "react";
import Lottie from "lottie-react";
import { gridStringOrNumberComparator } from '@mui/x-data-grid';

// project import
import { StyledDataGrid } from "../StyledDataGrid";
import store from "../../store/store";
import { setManageUsersFilter } from "../../store/reducers/dataSlice";
import {
    setLicensesToDelete,
    setSelectedUsers,
    subscriptionAddRestrictions,
    subscriptionRemoveRestrictions,
    subscriptionAddUsers,
    getSubscriptionData,
    setUsersAmount,
    setUsersToDelete,
    setUsersToRemoveAdminPermissions,
    setUsersToAddAdminPermissions,
    setUsersToAddToSubscription,
} from "../../store/reducers/userSlice";
import {
    openAlertDeleteLicenseDialog,
    openAlertAddUserToSubscriptionDialog,
    openDeleteUserFromManageUsersDialog, openRemoveAdminPermissionsDialog, openAddAdminPermissionsDialog
} from "../../store/reducers/dialogSlice";
import { ALERT_HEIGHT, BREADCRUMBS_HEIGHT, HEADER_HEIGHT, MANAGE_USERS_APPBAR_HEIGHT } from "../../constants/size";
import { LicensedUsersIcon, NoLicensedUsersIcon } from "./LicenseIcons";
import { showNotification } from "../../store/reducers/notificationSlice";
import CustomLoadingOverlay from "../CustomLoadingOverlay";
import animation from '../../loading.json'
import AvatarsSharedBy from '../../_mock/avatarsSharedBy';
import mockedStatus from '../../_mock/status';
import { UserAvatar } from "../UserAvatar";

export default function ManageUsers() {
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    const noLicenseAlert = useSelector((state) => state.user.noLicenseAlert);
    const showAlert = domainInstallAlert || noLicenseAlert;
    const topOffset = HEADER_HEIGHT + BREADCRUMBS_HEIGHT + MANAGE_USERS_APPBAR_HEIGHT + 73 + (showAlert ? ALERT_HEIGHT : 0);
    const isAlertBar = domainInstallAlert || noLicenseAlert;
    const subscription = useSelector((state) => state.user.subscription);
    const shareableUsers = useSelector((state) => state.user.shareableUsers);
    const selectedUsers = useSelector((state) => state.user.selectedUsers);
    const user = useSelector((state) => state.user);
    const contacts = useSelector((state) => state.contacts.contacts);
    const manageUsersFilter = useSelector((state) => state.data.manageUsersFilter);
    const statusFirstLoad = useSelector((state) => state.contacts.statusFirstLoad);
    const userInitialized = useSelector((state) => state.user.userInitialized);
    const domainInfoInitialized = useSelector((state) => state.user.domainInfoInitialized);
    const groupsToShare = useSelector((state) => state.groups.groupsToShare);
    const [sortModel, setSortModel] = React.useState([
        {
            field: 'name',
            sort: 'asc'
        }
    ]);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const slots = useMemo(() => ({
        columnMenu: CustomColumnMenu,
        loadingOverlay: CustomLoadingOverlay,
    }), []);

    if (!userInitialized || !domainInfoInitialized) return null; // Waiting for Alerts information to prevent table drift

    const handleRemoveLicenseClick = (event, user) => {
        event.stopPropagation();
        store.dispatch(setLicensesToDelete([user]));
        store.dispatch(openAlertDeleteLicenseDialog(true));
    };
    const handleRemoveSelectedLecensesClick = () => {
        const licensesToDelete = selectedUsers.reduce((acc, item) => {
            const user = rows.find(user => user.id === item);
            if (user) {
                acc.push(user);
            }
            return acc;
        }, []);
        store.dispatch(setLicensesToDelete(licensesToDelete));
        store.dispatch(openAlertDeleteLicenseDialog(true));
    };
    const handleAddUserToSubscriptionClick = async (event, user) => {
        event.stopPropagation();
        store.dispatch(setUsersToAddToSubscription([user]));
        store.dispatch(openAlertAddUserToSubscriptionDialog(true));
    }
    const handleAddSelectedUsersToSubscriptionClick = async () => {
        const licensesToAdd = selectedUsers.reduce((acc, item) => {
            const user = rows.find(user => user.id === item);
            return user ? [...acc, user] : acc;
        }, []);
        store.dispatch(setUsersToAddToSubscription(licensesToAdd));
        store.dispatch(openAlertAddUserToSubscriptionDialog(true));
    };
    const handleDeleteUserClick = (event, user) => {
        event.stopPropagation();
        store.dispatch(setUsersToDelete([user]));
        store.dispatch(openDeleteUserFromManageUsersDialog(true));
    }
    const handleDeleteSelectedUsersClick = () => {
        const usersToDelete = selectedUsers.reduce((acc, item) => {
            const user = rows.find(user => user.id === item);
            if (user) {
                acc.push(user);
            }
            return acc;
        }, []);
        store.dispatch(setUsersToDelete(usersToDelete));
        store.dispatch(openDeleteUserFromManageUsersDialog(true));
    }
    const handleRemoveAdminPermissionClick = (event, user) => {
        event.stopPropagation();
        store.dispatch(setUsersToRemoveAdminPermissions({ selectedUsers: [user], rowsUsers: rows }));
        store.dispatch(openRemoveAdminPermissionsDialog(true));
    }
    const handleRemoveSelectedAdminsPermissionsClick = () => {
        const users = selectedUsers.reduce((acc, item) => {
            const user = rows.find(user => user.id === item);
            if (user) {
                acc.push(user);
            }
            return acc;
        }, []);
        store.dispatch(setUsersToRemoveAdminPermissions({ selectedUsers: users, rowsUsers: rows }));
        store.dispatch(openRemoveAdminPermissionsDialog(true));
    }

    const handleAddAdminPermissionClick = (event, user) => {
        event.stopPropagation();
        store.dispatch(setUsersToAddAdminPermissions([user]));
        store.dispatch(openAddAdminPermissionsDialog(true));
    }
    const handleAddSelectedAdminsPermissionsClick = () => {
        const users = selectedUsers.reduce((acc, item) => {
            const user = rows.find(user => user.id === item);
            if (user) {
                acc.push(user);
            }
            return acc;
        }, []);
        store.dispatch(setUsersToAddAdminPermissions(users));
        store.dispatch(openAddAdminPermissionsDialog(true));
    }

    const handleAddToRestrictedClick = (event, user) => {
        event.stopPropagation();
        store.dispatch(subscriptionAddRestrictions([user.email]));
    };
    const handleDeleteFromRestrictedClick = (event, user) => {
        event.stopPropagation();
        store.dispatch(subscriptionRemoveRestrictions([user.email]));
    };
    const customSortComparator = (v1, v2, param1, param2) => {
        // Сортировка по isAdmin
        if (v1.isAdmin !== v2.isAdmin) {
            return v1.isAdmin ? -1 : 1; // Администраторы идут первыми
        }

        // Если оба одинаковы по isAdmin, сортируем по лицензии
        if (v1.license !== v2.license) {
            return v1.license === "Licensed" ? -1 : 1; // Сначала с лицензией
        }

        // Если и isAdmin, и license одинаковы, сортируем по имени
        return gridStringOrNumberComparator(v1.name, v2.name, param1, param2);
    };
    const getPhotoFormContacts = (email) => {
        const contact = contacts.find(contact => contact.email === email);
        return contact?.photo;
    };
    const columns = [
        {
            field: 'name',
            headerName: 'Name and email',
            minWidth: 250,
            flex: 1,
            sortable: !selectedUsers.length,
            disableColumnMenu: true,
            // valueGetter: (value) => {
            //     const obj = {
            //         name: value.value,
            //         isAdmin: value.row.isAdmin,
            //         license: value.row.license, // Добавляем лицензии для сортировки
            //     }
            //     return obj
            // },
            // valueFormatter: (value) => {
            //     let formattedName = value.value.name;
            //     if (value.value.isAdmin) {
            //         formattedName += ' (admin)';
            //     }
            //     if (value.license === 'Licensed') {
            //         formattedName += ' (licensed)';
            //     }
            //     return formattedName;
            // },
            // sortComparator: customSortComparator,
            renderHeader: (params) => (
                selectedUsers.length ? <>
                    <Typography sx={{
                        color: '#3B7DED',
                        fontFamily: 'Open Sans',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '24px',
                        letterSpacing: '0',
                    }}>Selected: {selectedUsers.length}</Typography>
                    {/* <Tooltip
                        placement="bottom"
                        title='Remove license'
                    >
                        <IconButton
                            onClick={handleDeleteSelectedUsersClick}
                        >
                            <DeleteOutlineOutlined
                                sx={{
                                    width: 24,
                                    height: 24,
                                    flexShrink: 0,
                                    color: 'rgba(0, 0, 0, 0.54)',
                                }}
                            />
                        </IconButton>
                    </Tooltip> */}
                </> : <span>
                    {params.colDef.headerName}
                </span>

            ),
            renderCell: params => {
                return (
                    <Stack direction={'row'} spacing={'20px'} sx={{ alignItems: 'center' }}>
                        <UserAvatar alt={params.row.name} src={getPhotoFormContacts(params.row.email)} sx={{ width: 40, height: 40 }} />
                        <Stack>
                            <Stack direction={'row'} spacing={'4px'} sx={{ alignItems: 'center' }}>
                                <Typography
                                    sx={{
                                        color: "rgba(0, 0, 0, 0.87)",
                                        fontSize: 14,
                                        fontFamily: "Roboto",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "20px",
                                        letterSpacing: "0.2px",
                                    }}
                                >
                                    {params.value || params.row.email}
                                </Typography>
                                {params.row.isAdmin && <Tooltip title={'Admin'}><StarOutlined sx={{ width: 18, height: 18, color: "rgba(0, 0, 0, 0.54)" }} /></Tooltip>}
                            </Stack>
                            <Typography
                                sx={{
                                    color: "rgba(0, 0, 0, 0.54)",
                                    fontSize: 12,
                                    fontFamily: "Roboto",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "16px",
                                    letterSpacing: "0.2px",
                                }}
                            >
                                {params.row.email}
                            </Typography>
                        </Stack>
                    </Stack>
                )
            }
        },
        {
            field: 'license',
            headerName: 'License',
            minWidth: 100,
            flex: 1,
            sortable: !selectedUsers.length,
            renderHeader: (params) => (
                selectedUsers.length ? <></> : <span>
                    {params.colDef.headerName}
                </span>
            ),
            renderCell: params => (
                params.value === 'Licensed' ? <LicensedUsersIcon /> : <NoLicensedUsersIcon />
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 150,
            flex: 1,
            // sortable: !selectedUsers.length,
            sortable: false,
            renderHeader: (params) => (
                selectedUsers.length ? <></> : <span>
                    {params.colDef.headerName}
                </span>
            ),
            // renderCell: params => (
            //     <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
            //         {getSyncStatusIcon(params.value)}
            //         {/* {getStatusIcon('Revoked')} */}
            //         <Box>{params.value}</Box>
            //     </Stack>
            // )
            renderCell: (params) => {
                if (params.row.shareType === "GROUP") {
                    const group = groupsToShare[0];
                    const usersInDomainGroup = group.share.filter(
                        (user) => user.domain_group_email === params.id
                    );
                    const usersInDomainGroupCount =
                        usersInDomainGroup.length || params.row.count || 0;
                    const usersWithStatusOK = usersInDomainGroup.filter(
                        (user) => user.sync_status === "OK"
                    );
                    const groupSyncStatus =
                        usersInDomainGroupCount === usersWithStatusOK.length
                            ? "Shared"
                            : `Sharing ${usersWithStatusOK.length}/${usersInDomainGroupCount}`;
                    return (
                        <Stack
                            direction={"row"}
                            alignItems="center"
                            justifyContent="center"
                            spacing={1}
                        >
                            {getSyncStatusIcon(
                                groupSyncStatus === "Shared" ? "OK" : "ON_QUEUE"
                            )}
                            <Typography
                                sx={{
                                    color: "rgba(32, 33, 36, 1)",
                                    fontSize: 14,
                                    fontFamily: "Open Sans",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "24px",
                                    letterSpacing: "0",
                                }}
                            >
                                {groupSyncStatus}
                            </Typography>
                        </Stack>
                    );
                }
                // const status = mockedStatus[Math.floor(Math.random() * 10) + 1];
                return (
                    <Stack
                        direction={"row"}
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                    >
                        {getSyncStatusIcon(params.value)}
                        {/* {getSyncStatusIcon(status)} */}
                        <Typography
                            sx={{
                                color: "rgba(32, 33, 36, 1)",
                                fontSize: 14,
                                fontFamily: "Open Sans",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "24px",
                                letterSpacing: "0",
                            }}
                        >
                            {getSyncStatusText(params.value)}
                            {/* {getSyncStatusText(status)} */}
                        </Typography>
                    </Stack>
                );
            }
        },
        {
            field: 'sharedBy',
            headerName: 'Shared by',
            minWidth: 200,
            flex: 1,
            sortable: !selectedUsers.length,
            disableColumnMenu: true,
            renderHeader: (params) => (
                selectedUsers.length ? <></> : <span>
                    {params.colDef.headerName}
                </span>
            ),
            renderCell: params => {
                return <AvatarSharedBy sharedUsers={params.value} />
            }
        },
        {
            field: 'domainGroups',
            headerName: 'Domain groups',
            minWidth: 250,
            flex: 1,
            sortable: !selectedUsers.length,
            disableColumnMenu: true,
            renderHeader: (params) => (
                selectedUsers.length ? <></> : <span>
                    {params.colDef.headerName}
                </span>
            ),
            renderCell: params => {
                let tooltipText;
                if (params.value?.length > 0) {
                    // params.value = [
                    //     ...params.value,
                    //     {
                    //         "domain_group_email": "test_group_31@antosha.net",
                    //         "domain_group_id": 31,
                    //         "domain_group_name": "test_group_31"
                    //     },
                    //     {
                    //         "domain_group_email": "for_dima_test1@antosha.net",
                    //         "domain_group_id": 324,
                    //         "domain_group_name": "For Dima Test1"
                    //     }
                    // ]
                    tooltipText = params.value.slice(1).map(item => item.domain_group_name).join('\n');
                }

                return (
                    <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
                        <Stack>
                            <Box sx={{ maxWidth: 190 }}>
                                <Typography
                                    noWrap
                                    sx={{
                                        color: "rgba(0, 0, 0, 0.87)",
                                        fontSize: 14,
                                        fontFamily: "Roboto",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "20px",
                                        letterSpacing: "0.2px",
                                    }}
                                >
                                    {params.value?.[0]?.domain_group_name}
                                </Typography>
                            </Box>
                            {params.value?.length ? (
                                <Box sx={{ maxWidth: 190 }}>
                                    <Typography
                                        noWrap
                                        sx={{
                                            color: "rgba(0, 0, 0, 0.54)",
                                            fontSize: 12,
                                            fontFamily: "Roboto",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "16px",
                                            letterSpacing: "0.2px",
                                        }}
                                    >
                                        {params.value?.[0]?.domain_group_email}
                                    </Typography>
                                </Box>
                            ) : null}
                        </Stack>
                        {params.value?.length > 1 ? (
                            <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{tooltipText}</span>}>
                                <Chip
                                    sx={{
                                        marginRight: 1,
                                        maxWidth: 132,
                                        border: '1px solid rgba(0, 0, 0, 0.12)',
                                        borderRadius: '8px'
                                    }}
                                    label={
                                        <Typography noWrap variant={'label'}>{`+${params.value?.length - 1}`}</Typography>
                                    }
                                    size="medium"
                                    variant={'outlined'}
                                    color={'default'}
                                />
                            </Tooltip>
                        ) : null}
                    </Stack>
                )
            }
        },
        {
            field: 'memberShips',
            headerName: 'Labels',
            minWidth: 430,
            // minWidth: 286,
            flex: 1,
            sortable: !selectedUsers.length,
            disableColumnMenu: true,
            renderHeader: (params) => (
                selectedUsers.length ? <Box sx={{ minWidth: 430, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <Tooltip
                        placement="bottom"
                        title='Appoint admin'
                    >
                        <IconButton
                            onClick={() => handleAddSelectedAdminsPermissionsClick()}
                        >
                            <SvgIcon>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.81 8.62L22 9.24L19.9721 11H17.5367L18.09 10.52L13.71 10.14L12 6.1L10.3 10.13L5.92 10.51L9.24 13.39L8.24 17.67L11 16.0037V17.8736L5.82 21L7.46 13.97L2 9.24L9.19 8.63L12 2L14.81 8.62Z" fill="#757575" />
                                    <path d="M21 18H18V21H16V18H13V16H16V13H18V16H21V18Z" fill="#757575" />
                                </svg>
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        placement="bottom"
                        title='Remove admin'
                    >
                        <IconButton
                            onClick={() => handleRemoveSelectedAdminsPermissionsClick()}
                        >
                            <SvgIcon>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.28961 7.69961L2.09961 3.50961L3.51961 2.09961L21.8996 20.4896L20.4896 21.8996L6.28961 7.69961Z" fill="#757575" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.43439 8.05338L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27L18.18 21L16.9119 15.5309L14.7759 13.3949L14.77 13.4L15.77 17.68L12 15.4L8.24 17.67L9.24 13.39L5.92 10.51L10.3 10.13L10.6593 9.27827L9.43439 8.05338ZM13.2437 9.03838L12 6.1L11.4975 7.29121L10.2756 6.06863L12 2L14.81 8.62L22 9.24L17.4189 13.2159L16.2874 12.0837L18.09 10.52L14.405 10.2003L13.2437 9.03838Z" fill="#757575" />
                                </svg>
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        placement="bottom"
                        title='Add license'
                    >
                        <IconButton
                            onClick={() => handleAddSelectedUsersToSubscriptionClick()}
                        >
                            <BookmarkAddOutlined />
                        </IconButton>
                    </Tooltip>
                </Box> : <span>
                    {params.colDef.headerName}
                </span>
            ),
            valueGetter: (params) => params.row.groups,
            renderCell: (params) => {
                let tooltipTextArray;
                if (params.value?.length > 3) {
                    tooltipTextArray = params.value.slice(2);
                }
                return (
                    params.value?.slice(0, 3).map((item, index) => (
                        <Tooltip
                            key={index}
                            title={
                                (index === 2 && params.value.length > 3)
                                    ? <Box sx={{ maxWidth: 300 }}>
                                        {tooltipTextArray.map((text, idx) => (
                                            <span
                                                key={idx}
                                                style={{
                                                    display: 'block',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}
                                            >
                                                {text}
                                            </span>
                                        ))}
                                    </Box>
                                    : ''
                            }
                        >
                            <Chip
                                sx={{
                                    marginRight: 1,
                                    maxWidth: 132,
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderRadius: '8px'
                                }}
                                label={
                                    (index === 2 && params.value.length > 3) ? (
                                        <Typography variant={'label'}>+{params.value.length - 2}</Typography>
                                    ) : (
                                        <Typography noWrap variant={'label'}>{item}</Typography>
                                    )
                                }
                                size="medium"
                                variant={'outlined'}
                                color={'default'}
                            />
                        </Tooltip>
                    ))
                );
            }
        },
        {
            field: 'action',
            headerName: '',
            width: 202,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: (params) => (
                selectedUsers.length ? <Stack direction={'row'} spacing={0}>
                    <Tooltip
                        placement="bottom"
                        title='Appoint admin'
                    >
                        <span>
                            <IconButton
                                onClick={() => handleAddSelectedAdminsPermissionsClick()}
                            >
                                <SvgIcon>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M14.81 8.62L22 9.24L19.9721 11H17.5367L18.09 10.52L13.71 10.14L12 6.1L10.3 10.13L5.92 10.51L9.24 13.39L8.24 17.67L11 16.0037V17.8736L5.82 21L7.46 13.97L2 9.24L9.19 8.63L12 2L14.81 8.62Z"
                                            fill="#757575" />
                                        <path d="M21 18H18V21H16V18H13V16H16V13H18V16H21V18Z" fill="#757575" />
                                    </svg>
                                </SvgIcon>
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip
                        placement="bottom"
                        title='Remove admin'
                    >
                        <span>
                            <IconButton
                                onClick={() => handleRemoveSelectedAdminsPermissionsClick()}
                            >
                                <SvgIcon>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.28961 7.69961L2.09961 3.50961L3.51961 2.09961L21.8996 20.4896L20.4896 21.8996L6.28961 7.69961Z"
                                            fill="#757575" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.43439 8.05338L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27L18.18 21L16.9119 15.5309L14.7759 13.3949L14.77 13.4L15.77 17.68L12 15.4L8.24 17.67L9.24 13.39L5.92 10.51L10.3 10.13L10.6593 9.27827L9.43439 8.05338ZM13.2437 9.03838L12 6.1L11.4975 7.29121L10.2756 6.06863L12 2L14.81 8.62L22 9.24L17.4189 13.2159L16.2874 12.0837L18.09 10.52L14.405 10.2003L13.2437 9.03838Z"
                                            fill="#757575" />
                                    </svg>
                                </SvgIcon>
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip
                        placement="bottom"
                        title='Add license'
                    >
                        <IconButton
                            onClick={() => handleAddSelectedUsersToSubscriptionClick()}
                        >
                            <BookmarkAddOutlined />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        placement="bottom"
                        title='Remove license'
                    >
                        <IconButton
                            onClick={() => handleRemoveSelectedLecensesClick()}
                        >
                            <SvgIcon>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.58063 8.41055L1.39062 4.22055L2.81062 2.81055L21.1906 21.2005L19.7806 22.6105L5.58063 8.41055Z" fill="#757575" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5 5.01937L5.58063 5.6L7 7.01937V18L12 15.82L17 18V17.0194L19 19.0194V21L12 18L5 21V5.01937ZM19 16.1982V5C19 3.9 18.1 3 17 3H7C6.6569 3 6.33325 3.08756 6.0503 3.24144L7.80791 5H17V14.1971L19 16.1982Z" fill="#757575" />
                                </svg>
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        placement="bottom"
                        title='Delete user'
                    >
                        <span>
                            <IconButton
                                disabled
                                onClick={() => handleDeleteSelectedUsersClick()}
                            >
                                <DeleteOutlineOutlined
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        flexShrink: 0,
                                        // color: 'rgba(0, 0, 0, 0.54)',
                                    }}
                                />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Stack> : <>
                    {params.colDef.headerName}
                </>
            ),
            renderCell: params => {
                // return params.row.license === 'Licensed' && !params.row.isAdmin && subscription.status !== 2 &&
                return <Box className="actionIcons">
                    <ActionMenu params={params}
                        handleRemoveLicenseClick={handleRemoveLicenseClick}
                        handleAddUserToSubscriptionClick={handleAddUserToSubscriptionClick}
                        handleDeleteUserClick={handleDeleteUserClick}
                        handleRemoveAdminPermissionClick={handleRemoveAdminPermissionClick}
                        handleAddAdminPermissionClick={handleAddAdminPermissionClick}
                    />
                </Box>
                // <Tooltip
                //     placement="bottom"
                //     title='Add to restriction users'
                // >
                //     <IconButton
                //         onClick={(event) => handleAddToRestrictedClick(event, params.row)}
                //     >
                //         <CloseOutlined
                //             sx={{
                //                 width: 24,
                //                 height: 24,
                //                 flexShrink: 0,
                //                 color: 'red',
                //             }}
                //         />
                //     </IconButton>
                // </Tooltip>
                // <Tooltip
                //     placement="bottom"
                //     title='Remove from restriction users'
                // >
                //     <IconButton
                //         onClick={(event) => handleDeleteFromRestrictedClick(event, params.row)}
                //     >
                //         <DeleteOutlineOutlined
                //             sx={{
                //                 width: 24,
                //                 height: 24,
                //                 flexShrink: 0,
                //                 color: 'red',
                //             }}
                //         />
                //     </IconButton>
                // </Tooltip>
                // </Stack>
            }
        },
        {
            field: "customSort",
            headerName: "Custom Sort",
            width: 200,
            valueGetter: (params) => {
                // Добавляем вес для сортировки
                if (params.row.isAdmin) return `1_${params.row.name}`;
                if (params.row.license === "Licensed") return `2_${params.row.name}`;
                return `3_${params.row.name}`;
            },
            sortComparator: (v1, v2) => v1.localeCompare(v2), // Сортируем строки с весом
            sortable: true,
        },
    ];
    const rows = getUsersByFilter(getUsers(subscription, contacts, shareableUsers.users, user), {
        status: manageUsersFilter.status,
        license: manageUsersFilter.license
    });
    return (
        <StyledDataGrid
            sx={{
                height: '100%',
                width: '100%',
                // "& .MuiDataGrid-row": {
                //     height: 56, // Row height
                //     '&:not(:last-child)': {
                //         marginBottom: '6px' // Space between rows
                //     },
                //     '&:first-child': {
                //         marginTop: '8px', // Set the top padding for the first row
                //     },
                // },
                "& .MuiDataGrid-cell[data-field='action']": {
                    textAlign: 'right',
                    justifyContent: 'flex-end',
                    width: '100%',
                },
                "& .MuiDataGrid-columnHeader[data-field='action']": {
                    padding: 0, // Только для action
                },
                "& .MuiDataGrid-columnHeader[data-field='memberShips']": {
                    padding: 0, // Только для memberShips
                },

            }}
            key={`${windowSize.width}-${windowSize.height}`}
            checkboxSelection
            rows={rows}
            columns={columns}
            columnHeaderHeight={56}
            rowHeight={56}
            autoPageSize
            disableColumnMenu={selectedUsers.length !== 0}
            onRowSelectionModelChange={(newRowSelectionModel) => {
                store.dispatch(setSelectedUsers(newRowSelectionModel));
            }}
            rowSelectionModel={selectedUsers}
            // isRowSelectable={(params) => params.row.license === 'Licensed' && !params.row.isAdmin}
            loading={statusFirstLoad === 'loading'}
            // sortModel={sortModel}
            // onSortModelChange={(model) => setSortModel(model)}
            initialState={{
                sorting: {
                    // sortModel: [{ field: 'name', sort: 'asc' }],
                    sortModel: [{ field: 'customSort', sort: 'asc' }],
                },
            }}
            slots={slots}
            slotProps={{
                loadingOverlay: { topOffset: 600, rowHeight: 56 }, // Passing props
            }}
            columnVisibilityModel={{
                sharedBy: false,
                status: false,
                domainGroups: false,
                memberShips: false,
                customSort: false,
            }}
        />
    );
}

const getSyncStatusText = (status) => {
    let result = '';
    switch (status) {
        case 'ON_QUEUE':
            result = 'Sharing';
            break;
        case 'OK':
            result = 'Shared';
            break;
        case 'ACTIVE':
            result = 'Shared';
            break;
        case 'WAITING_LABEL':
            result = 'Waiting for access';
            break;
        case 'WAITING_CONTACTS':
            result = 'Sharing';
            break;
        case 'REVOKE':
            result = 'Revoked';
            break;
        case 'PENDING':
            result = 'Pending';
            break;
        case 'INVITATION_SENT':
            result = 'Invitation sent';
            break;
        default:
            result = 'Waiting for access';
    }
    return result;
}

const getSyncStatusIcon = (status) => {
    let result = '';
    switch (status) {
        case 'ON_QUEUE':
            result = <Box sx={{ width: 24, height: 24 }}><Lottie animationData={animation} loop /></Box>;
            break;
        case 'OK':
            result = <CheckCircle color={'success'} />;
            break;
        case 'ACTIVE':
            result = <CheckCircle color={'success'} />;
            break;
        case 'WAITING_LABEL':
            result = <ErrorOutlined color={'error'} />;
            break;
        case 'WAITING_CONTACTS':
            result = <Box sx={{ width: 24, height: 24 }}><Lottie animationData={animation} loop /></Box>;
            break;
        case 'SHARING':
            result = <Box sx={{ width: 24, height: 24 }}><Lottie animationData={animation} loop /></Box>;
            break;
        case 'REVOKE':
            result = <SvgIcon>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.66602 10.0013C1.66602 5.4013 5.39935 1.66797 9.99935 1.66797C14.5993 1.66797 18.3327 5.4013 18.3327 10.0013C18.3327 14.6013 14.5993 18.3346 9.99935 18.3346C5.39935 18.3346 1.66602 14.6013 1.66602 10.0013ZM10 3.125V5C12.7625 5 15 7.2375 15 10C15 10.9812 14.7125 11.8938 14.225 12.6625L13.3125 11.75C13.5937 11.2312 13.75 10.6313 13.75 10C13.75 7.93125 12.0688 6.25 10 6.25V8.125L7.5 5.625L10 3.125ZM6.25 10C6.25 12.0688 7.93125 13.75 10 13.75V11.875L12.5 14.375L10 16.875V15C7.2375 15 5 12.7625 5 10C5 9.01875 5.2875 8.10625 5.775 7.3375L6.6875 8.25C6.40625 8.76875 6.25 9.36875 6.25 10Z" fill="#D32F2F" />
                </svg>
            </SvgIcon>;
            break;
        case 'PENDING':
            result = <AccessTimeFilledOutlined sx={{ color: 'rgba(0, 0, 0, 0.4)' }} />;
            break;
        case 'INVITATION_SENT':
            result = <ErrorOutlined sx={{ color: 'rgba(237, 159, 2, 1)' }} />;
            break;
        default:
            result = <ErrorOutlined color={'error'} />;
    }
    return result;
}

function getStatusIcon(status) {
    let icon;
    switch (status) {
        case 'Normal':
            icon = <CheckCircle color={"success"} />;
            break;
        case 'Deleted':
            icon = <Cancel color={"error"} />;
            break;
        case 'Suspended':
            icon = <PauseCircleFilledOutlined sx={{ color: 'rgba(237, 159, 2, 1)' }} />;
            break;
        case 'Banned':
            icon = <RemoveCircleOutlined color={"error"} />;
            break;
        case 'Revoked':
            icon = <ErrorOutlined color={"error"} />;
            break;
        case 'Pending':
            icon = <SvgIcon>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z"
                        fill="#ED9F02" />
                    <path
                        d="M12 6V3.75L9 6.75L12 9.75V7.5C14.4825 7.5 16.5 9.5175 16.5 12C16.5 12.7575 16.3125 13.4775 15.975 14.1L17.07 15.195C17.655 14.2725 18 13.1775 18 12C18 8.685 15.315 6 12 6ZM12 16.5C9.5175 16.5 7.5 14.4825 7.5 12C7.5 11.2425 7.6875 10.5225 8.025 9.9L6.93 8.805C6.345 9.7275 6 10.8225 6 12C6 15.315 8.685 18 12 18V20.25L15 17.25L12 14.25V16.5Z"
                        fill="white" />
                </svg>
            </SvgIcon>;
            break;
        case 'Unknown':
            icon = <HelpOutlined sx={{ color: 'rgba(237, 159, 2, 1)' }} />;
            break;
        case 'Clear Filter':
            icon = <Clear color={"inherit"} />;
            break;
        default:
            icon = <HelpOutlined sx={{ color: 'rgba(237, 159, 2, 1)' }} />;
    }
    return icon;
}

const getUsersByFilter = (users, filter) => {
    let filteredUsers = users;
    if (filter.status) {
        filteredUsers = users.filter(user => user.status === filter.status);
    }
    if (filter.license) {
        filteredUsers = filteredUsers.filter(user => user.license === filter.license);
    }
    return filteredUsers;
}

function CustomColumnMenu(props) {
    const manageUsersFilter = useSelector((state) => state.data.manageUsersFilter);
    const itemProps = {
        colDef: props.colDef,
        hideMenu: props.hideMenu,
    };
    // const statuses = ['Normal', 'Deleted', 'Suspended', 'Banned', 'Revoked', 'Pending', 'Unknown'];
    const statuses = ['Normal', 'Deleted', 'Banned', 'Unknown'];
    const licenses = ['Licensed', 'Unlicensed'];
    return (
        <>
            {itemProps.colDef.field === 'status' ? (
                [...statuses.map(status => <StatusMenuComponent key={uuidv4()} {...itemProps} status={status} />),
                <><Divider /><MenuItem onClick={(event) => {
                    props.hideMenu(event);
                    store.dispatch(setManageUsersFilter({
                        license: manageUsersFilter.license ?? null,
                        status: null
                    }));
                }}>
                    <Stack spacing={1} direction={'row'} sx={{ alignItems: 'center' }}><Clear
                        color={"inherit"} /><Typography>Clear filter</Typography></Stack>
                </MenuItem></>]
            ) : null}
            {itemProps.colDef.field === 'license' ? (
                [...licenses.map(license => <LicenseMenuComponent key={uuidv4()} {...itemProps} license={license} />),
                <><Divider /><MenuItem onClick={(event) => {
                    props.hideMenu(event);
                    store.dispatch(setManageUsersFilter({ license: null, status: manageUsersFilter.status ?? null }));
                }}>
                    <Stack spacing={1} direction={'row'} sx={{ alignItems: 'center' }}><Clear
                        color={"inherit"} /><Typography>Clear filter</Typography></Stack>
                </MenuItem></>]
            ) : null}
        </>
    );
}

function StatusMenuComponent({ hideMenu, status }) {
    const manageUsersFilter = useSelector((state) => state.data.manageUsersFilter);
    const handleClickStatusFilter = (status) => {
        const filter = status === 'Clear Filter' ? {
            license: null,
            status: null
        } : { license: manageUsersFilter.license ?? null, status };
        store.dispatch(setManageUsersFilter(filter));
    };
    return (
        <MenuItem onClick={(event) => {
            hideMenu(event);
            handleClickStatusFilter(status);
        }}>
            <ListItemIcon>
                {getSyncStatusIcon(status)}
            </ListItemIcon>
            <ListItemText>{status}</ListItemText>
        </MenuItem>
    )
}

function LicenseMenuComponent({ hideMenu, license }) {
    const manageUsersFilter = useSelector((state) => state.data.manageUsersFilter);
    const handleClickLicenseFilter = (license) => {
        const filter = license === 'Clear Filter' ? { license: null, status: null } : {
            license,
            status: manageUsersFilter.status ?? null
        };
        store.dispatch(setManageUsersFilter(filter));
    };
    return (
        <MenuItem onClick={(event) => {
            hideMenu(event);
            handleClickLicenseFilter(license);
        }}>
            <ListItemIcon>
                {license === 'Licensed' ? <SvgIcon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M13 8C13 5.79 11.21 4 9 4C6.79 4 5 5.79 5 8C5 10.21 6.79 12 9 12C11.21 12 13 10.21 13 8ZM1 18V20H17V18C17 15.34 11.67 14 9 14C6.33 14 1 15.34 1 18Z"
                            fill="black" fillOpacity="0.54" />
                        <path d="M23 8L18 13L15 10" stroke="black" strokeOpacity="0.54" strokeWidth="2" />
                    </svg>
                </SvgIcon> : <SvgIcon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M13 8C13 5.79 11.21 4 9 4C6.79 4 5 5.79 5 8C5 10.21 6.79 12 9 12C11.21 12 13 10.21 13 8ZM1 18V20H17V18C17 15.34 11.67 14 9 14C6.33 14 1 15.34 1 18Z"
                            fill="black" fillOpacity="0.54" />
                        <path d="M22 8L19 11M16 14L19 11M19 11L16 8L22 14" stroke="black" strokeOpacity="0.54"
                            strokeWidth="2" />
                    </svg>
                </SvgIcon>}

            </ListItemIcon>
            <ListItemText><Typography>{license}</Typography></ListItemText>
        </MenuItem>
    )
}

const getUsers = (subscription, contacts, shareableUsers, user) => {
    const licensedUsers = subscription.status === 2 ? subscription.connected_users : subscription.licensed_users;
    let connectedUsers = licensedUsers.map((connData, index) => {
        const ownerIds = connData.groups.map(group => group.owner_id);
        const foundUsers = licensedUsers.filter(user => ownerIds.includes(user.user_id));
        const groupNames = connData.groups ? connData.groups.map(el => el.name) : [];
        let contact = { ...contacts.find(el => el.email === connData.email) };
        // const isAdmin = subscription.subscription.owner_id ? subscription.subscription.owner_id === connData.user_id : connData.is_admin;
        const isAdmin = connData.is_admin || false;
        if (contact.length) {
            contact.id = connData.email;
            contact.groups = groupNames;
            contact.domainGroups = connData.domains_groups_with_emails;
            contact.status = connData.status;
            contact.license = 'Licensed';
            contact.userId = connData.user_id;
            contact.isAdmin = isAdmin;
            contact.name = connData.name || connData.email;
            contact.sharedBy = foundUsers;
        } else {
            const userData = user.user;
            if (connData.email === userData.email) {
                contact = {
                    id: connData.email,
                    email: connData.email,
                    groups: groupNames,
                    domainGroups: connData.domains_groups_with_emails,
                    name: userData.name || userData.email,
                    photo: userData.photo,
                    status: connData.status,
                    license: 'Licensed',
                    userId: connData.user_id,
                    isAdmin,
                    sharedBy: foundUsers,
                }
            } else {
                contact = {
                    id: connData.email,
                    email: connData.email,
                    groups: groupNames,
                    domainGroups: connData.domains_groups_with_emails || null,
                    status: connData.status,
                    license: 'Licensed',
                    userId: connData.user_id,
                    isAdmin,
                    sharedBy: foundUsers,
                }
                const shareable = shareableUsers.find(el => el.email === connData.email);
                contact.name = shareable ? shareable.name || connData.email : connData.email;
            }
        }
        return contact;
    })
    // Restricted and deleted users
    if (subscription.status !== 2) {
        const uniqueRestrictedUsers = subscription.restricted_users ? subscription.restricted_users.filter(restrictedUser => {
            return !connectedUsers.some(connectedUser => connectedUser.email === restrictedUser.email);
        }) : [];
        const restrictedUsers = uniqueRestrictedUsers.map(item => {
            const contact = contacts.find(el => el.email === item.email);
            const name = contact ? contact.name : item.email;
            return {
                ...item,
                status: 'Banned',
                id: item.user_id,
                license: 'Unlicensed',
                name,
                isAdmin: false,
                sharedBy: [],
            }
        });
        connectedUsers = [...connectedUsers, ...restrictedUsers];
        const uniqueDeletedUsers = subscription.deleted_users ? subscription.deleted_users.filter(deletedUser => {
            return !connectedUsers.some(connectedUser => connectedUser.email === deletedUser.email);
        }) : [];
        const deletedUsers = uniqueDeletedUsers.map(item => {
            const contact = contacts.find(el => el.email === item.email);
            const name = contact ? contact.name : item.email;
            return {
                ...item,
                status: 'Deleted',
                id: item.user_id,
                license: 'Unlicensed',
                name,
                isAdmin: false,
                sharedBy: [],
            }
        });
        connectedUsers = [...connectedUsers, ...deletedUsers];
    }
    // Domain users
    if (user.user.domain_user && user.domainData.users) {
        const uniqueDomainUsers = user.domainData.users.filter(domainUser => {
            return !connectedUsers.some(connectedUser => connectedUser.email === domainUser.email);
        });
        const domainUsers = uniqueDomainUsers.map(domainUser => {
            const licensedUser = subscription.licensed_users.find(licensedUser => licensedUser.email === domainUser.email);
            return {
                id: domainUser.email,
                email: domainUser.email,
                name: domainUser.name?.fullName || domainUser.email,
                groups: null,
                domainGroups: domainUser && domainUser.domains_groups?.length ? domainUser.domains_groups.join(',') : '',
                status: domainUser.status,
                license: licensedUser ? 'Licensed' : 'Unlicensed',
                isAdmin: domainUser.email === user.user.email && user.user.domain_admin,
                userId: null,
                sharedBy: [],
            }
        });
        connectedUsers = [...connectedUsers, ...domainUsers];
    }
    connectedUsers = connectedUsers.map((row, index) => ({ ...row, id: index }));
    return connectedUsers
}

const getUserStatus = (subscription, connData) => {
    const isDeleted = subscription.deleted_users ? subscription.deleted_users.find(item => item.email === connData.email) : false;
    if (isDeleted) return 'Deleted';
    const isBanned = subscription.restricted_users ? subscription.restricted_users.find(item => item.email === connData.email) : false;
    if (isBanned) return 'Banned';
    return 'Normal'
}

const AvatarSharedBy = ({ sharedUsers }) => {
    // if (!users) return null;
    // users = AvatarsSharedBy;
    // function getRandomUsers(users) {
    //     const randomCount = Math.floor(Math.random() * users.length) + 1;
    //     const shuffledUsers = users.sort(() => 0.5 - Math.random());
    //     return shuffledUsers.slice(0, randomCount);
    // }
    // const sharedUsers = getRandomUsers(users);
    // const sharedUsers = users;
    const extraUsers = sharedUsers.slice(4);
    const maxUsersInTooltip = 20;
    const tooltipText = extraUsers.length > 0
        ? extraUsers.slice(0, maxUsersInTooltip).map(user => user.email).join('\n') + (extraUsers.length > maxUsersInTooltip ? '\n...' : '')
        : '';
    return (
        <AvatarGroup max={5}
            key={'SharedUsersAvatar'}
            spacing={'medium'}
            renderSurplus={(surplus) => <Tooltip
                placement="top"
                title={<span style={{ whiteSpace: 'pre-line' }}>{tooltipText}</span>}>
                <span>+{surplus}</span>
            </Tooltip>}
            total={sharedUsers.length < 103 ? sharedUsers.length : 99 + 4}
            sx={{
                '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    color: 'rgba(0, 0, 0, 0.87)',
                    fontSize: 13,
                    fontFamily: "Open Sans",
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '16px',
                },
            }}
        >
            {
                sharedUsers.map((sharedUser, index) => {
                    return (
                        <Tooltip
                            placement="top"
                            title={sharedUser.email}
                            key={index}
                        >
                            <Badge
                                key={index}
                                variant={'dot'}
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                            >
                                <UserAvatar alt={sharedUser.name}
                                    sx={{ width: 32, height: 32 }}
                                    src={sharedUser.photo} />
                            </Badge>
                        </Tooltip>
                    )
                })
            }
        </AvatarGroup>
    )
}

const menuItemStyles = {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: '#212121',
};

function ActionMenu({ params, handleRemoveLicenseClick, handleAddUserToSubscriptionClick, handleDeleteUserClick, handleRemoveAdminPermissionClick, handleAddAdminPermissionClick }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVertOutlined
                    sx={{
                        width: 24,
                        height: 24,
                        flexShrink: 0,
                        color: 'rgba(0, 0, 0, 0.54)',
                    }}
                />
            </IconButton>

            <Paper sx={{ width: 320, maxWidth: '100%' }}>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuList>
                        {params.row.isAdmin ? <MenuItem
                            onClick={(event) => {
                                handleRemoveAdminPermissionClick(event, params.row);
                                handleClose();
                            }}
                        >
                            <ListItemIcon>
                                <SvgIcon>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.28961 7.69961L2.09961 3.50961L3.51961 2.09961L21.8996 20.4896L20.4896 21.8996L6.28961 7.69961Z" fill="#757575" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.43439 8.05338L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27L18.18 21L16.9119 15.5309L14.7759 13.3949L14.77 13.4L15.77 17.68L12 15.4L8.24 17.67L9.24 13.39L5.92 10.51L10.3 10.13L10.6593 9.27827L9.43439 8.05338ZM13.2437 9.03838L12 6.1L11.4975 7.29121L10.2756 6.06863L12 2L14.81 8.62L22 9.24L17.4189 13.2159L16.2874 12.0837L18.09 10.52L14.405 10.2003L13.2437 9.03838Z" fill="#757575" />
                                    </svg>
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText><Typography variant={'settingsMenu'} sx={{ color: '#212121' }}>Remove admin</Typography></ListItemText>
                        </MenuItem> :
                            <MenuItem
                                onClick={(event) => {
                                    handleAddAdminPermissionClick(event, params.row);
                                    handleClose();
                                }}
                                sx={menuItemStyles}
                            >
                                <ListItemIcon>
                                    <SvgIcon>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M14.81 8.62L22 9.24L19.9721 11H17.5367L18.09 10.52L13.71 10.14L12 6.1L10.3 10.13L5.92 10.51L9.24 13.39L8.24 17.67L11 16.0037V17.8736L5.82 21L7.46 13.97L2 9.24L9.19 8.63L12 2L14.81 8.62Z" fill="#757575" />
                                            <path d="M21 18H18V21H16V18H13V16H16V13H18V16H21V18Z" fill="#757575" />
                                        </svg>
                                    </SvgIcon>
                                </ListItemIcon>
                                <ListItemText><Typography variant={'settingsMenu'} sx={{ color: '#212121' }}>Appoint admin</Typography></ListItemText>
                            </MenuItem>}
                        {params.row.license === 'Licensed' ? !params.row.isAdmin ? <MenuItem onClick={(event) => {
                            handleRemoveLicenseClick(event, params.row);
                            handleClose();
                        }}>
                            <ListItemIcon>
                                <SvgIcon>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.58063 8.41055L1.39062 4.22055L2.81062 2.81055L21.1906 21.2005L19.7806 22.6105L5.58063 8.41055Z" fill="#757575" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5 5.01937L5.58063 5.6L7 7.01937V18L12 15.82L17 18V17.0194L19 19.0194V21L12 18L5 21V5.01937ZM19 16.1982V5C19 3.9 18.1 3 17 3H7C6.6569 3 6.33325 3.08756 6.0503 3.24144L7.80791 5H17V14.1971L19 16.1982Z" fill="#757575" />
                                    </svg>
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText><Typography variant={'settingsMenu'} sx={{ color: '#212121' }}>Remove license</Typography></ListItemText>
                        </MenuItem> : null :
                            <MenuItem onClick={(event) => {
                                handleAddUserToSubscriptionClick(event, params.row);
                                handleClose();
                            }}>
                                <ListItemIcon>
                                    <BookmarkAddOutlined sx={{ color: '#757575' }} />
                                </ListItemIcon>
                                <ListItemText><Typography variant={'settingsMenu'} sx={{ color: '#212121' }}>Add license</Typography></ListItemText>
                            </MenuItem>
                        }
                        {!params.row.isAdmin ? <MenuItem disabled onClick={(event) => {
                            handleDeleteUserClick(event, params.row);
                            handleClose();
                        }}>
                            <ListItemIcon>
                                <DeleteOutlineOutlined />
                            </ListItemIcon>
                            <ListItemText>Delete user</ListItemText>
                        </MenuItem> : null}
                    </MenuList>
                </Menu>
            </Paper>
        </div>
    );
}
