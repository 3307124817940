import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// project import
import axiosInstance from "../../api/axiosInstance";
import {abortPreviousRequest} from "../../api/abortPreviousRequest";

const initialState = {
    status: 'idle',
    createContactsStatus: 'idle',
    deleteContactsStatus: 'idle',
    error: null,
    contacts: [],
    rowSelectionModel: [],
    rowHover: false,
    statusFirstLoad: null,
    fullContact: [],
    contactsToDelete: [],
    selectedContacts: [],
    contactsInitialized: false,
}

export const getContactsAndLabels = createAsyncThunk('contacts/getContactsAndLabels', async (_, {rejectWithValue, requestId}) => {
        try {
            // console.log('getContactsAndLabels requestId', requestId, Date.now())
            const data = {};
            const response = await axiosInstance(data, 'get_contacts_and_labels', 'get', abortPreviousRequest(requestId, 'contacts/getContactsAndLabels'));
            return response.data;
        } catch (error) {
            if (error.code === "ERR_CANCELED") {
                // console.log('Aborted getContactsAndLabels requestId', requestId)
                return rejectWithValue('Request was aborted');
            }
            return rejectWithValue(error.message);
        }
    },
    {
        getPendingMeta: ({arg}) => {
            return {ignorePreviousRequest: true};
        }
    }
)

export const createContact = createAsyncThunk('contacts/createContact', async (payload, { rejectWithValue }) => {
    try {
        const phoneNumbers = payload.phones.map(item => ({
            type: item.label,
            value: item.phone
        }));
        const emailAddresses = payload.emails.map(item => ({
            type: item.label,
            value: item.email
        }));
        const data = {
            contact: {
                name: {
                    familyName: payload.surname,
                    givenName: payload.name,
                },
                organizations: [{
                    current: true,
                    name: payload.organization,
                    title: payload.jobTitle,
                }],
                emailAddresses,
                phoneNumbers,
                addresses: [
                    {
                        countryCode: payload.country,
                        region: payload.region,
                        city: payload.city,
                        streetAddress: payload.street,
                        extendedAddress: payload.street2,
                        postalCode: payload.streetPostcode,
                    }
                ],
                birthday: {
                    date: payload.birthday,
                },
                biographie:
                {
                    contentType: "TEXT_PLAIN",
                    value: payload.description
                }
            },
            labels_resources: [
                payload.groupResource,
            ]
        };
        const response = await axiosInstance(data, 'create_contact');
        response.data.message = 'Contact created'
        return response.data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
})

export const updateContact = createAsyncThunk('contacts/updateContact', async (payload, { rejectWithValue }) => {
    try {
        const phoneNumbers = payload.phones.map(item => ({
            type: item.label,
            value: item.phone
        }));
        const emailAddresses = payload.emails.map(item => ({
            type: item.label,
            value: item.email
        }));
        const data = {
            contact: {
                name: {
                    familyName: payload.surname,
                    givenName: payload.name,
                },
                organizations: [{
                    current: true,
                    name: payload.organization,
                    title: payload.jobTitle,
                }],
                emailAddresses,
                phoneNumbers,
                addresses: [{
                    countryCode: payload.country,
                    region: payload.region,
                    city: payload.city,
                    streetAddress: payload.street,
                    extendedAddress: payload.street2,
                    postalCode: payload.streetPostcode,
                }],
                birthday: {
                    date: payload.birthday,
                },
                biographie: {
                    contentType: "TEXT_PLAIN",
                    value: payload.description
                },
                etag: payload.etag,
                resourceName: payload.resourceName,
            }
        };
        const response = await axiosInstance(data, 'update_contact');
        response.data.message = 'Contact updated'
        return response.data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
})

export const getFullContact = createAsyncThunk('contacts/getFullContact', async (payload, {rejectWithValue}) => {
        try {
            const data = {contacts_resources: [payload]};
            const response = await axiosInstance(data, 'get_full_contacts');
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    },
    {
        getPendingMeta: ({arg}) => {
            return {ignorePreviousRequest: true};
        }
    }
)

export const deleteContacts = createAsyncThunk('contacts/deleteContacts', async (payload, { rejectWithValue }) => {
    try {
        const data = { contacts_resources: payload };
        const response = await axiosInstance(data, 'delete_contacts');
        response.data.message = 'Contact deleted'
        return response.data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
})

const dataSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        setRowSelectionModel: (state, action) => {
            state.rowSelectionModel = action.payload;
        },
        setRowHover: (state, action) => {
            state.rowHover = action.payload;
        },
        addContactsToGroup: (state, action) => {
            const contactIdsSet = new Set(action.payload.payload.contacts);
            const groupInfo = {
                contactGroupId: action.payload.payload.group.split('/')[1],
                contactGroupResourceName: action.payload.payload.group
            };
            state.contacts = state.contacts.map(contact => {
                if (contactIdsSet.has(contact.id)) {
                    return {
                        ...contact,
                        memberships: [
                            ...contact.memberships,
                            { contactGroupMembership: groupInfo }
                        ]
                    };
                }
                return contact;
            });
        },
        deleteContactsFromGroup: (state, action) => {
            const contactIdsSet = new Set(action.payload.payload.contacts);
            const groupToRemove = action.payload.payload.group;
            state.contacts = state.contacts.map(contact => {
                if (contactIdsSet.has(contact.id)) {
                    const filteredMemberships = contact.memberships.filter(
                        membership => membership.contactGroupMembership?.contactGroupResourceName !== groupToRemove
                    );
                    // Only return a new object if memberships have changed
                    if (filteredMemberships.length !== contact.memberships.length) {
                        return {
                            ...contact,
                            memberships: filteredMemberships
                        };
                    }
                }
                return contact;
            });
        },
        setContactsToDelete: (state, action) => {
            state.contactsToDelete = action.payload;
        },
        updateContacts: (state, action) => {
            state.contacts = action.payload.contacts;
        },
        updateStatusFirstLoad: (state, action) => {
            state.statusFirstLoad = action.payload.statusFirstLoad;
        },
        setSelectedContacts: (state, action) => {
            state.selectedContacts = action.payload.selectedContacts;
        },
        addNewContactToStore: (state, action) => {
            state.contacts = [...state.contacts, action.payload.contact];
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getContactsAndLabels.pending, (state, action) => {
                state.status = 'loading';
                if (!state.statusFirstLoad) {
                    state.statusFirstLoad = 'loading';
                }
            })
            .addCase(getContactsAndLabels.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.needLogin = action.payload.need_login;
                const {requestId} = action.meta;
                // console.log('Approved getContactsAndLabels requestId', requestId)
                if (!action.payload.need_login) {
                    state.contacts = action.payload.contacts;
                    state.contactsInitialized = true;
                }
            })
            .addCase(getContactsAndLabels.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.statusFirstLoad = 'failed';
            })

            .addCase(createContact.pending, (state) => {
                state.createContactsStatus = 'loading';
                if (!state.statusFirstLoad) {
                    state.statusFirstLoad = 'loading';
                }
            })
            .addCase(createContact.fulfilled, (state, action) => {
                state.createContactsStatus = 'succeeded';
                state.needLogin = action.payload.need_login;
            })
            .addCase(createContact.rejected, (state, action) => {
                state.createContactsStatus = 'failed';
                state.error = action.error.message;
                state.statusFirstLoad = 'failed';
            })

            .addCase(getFullContact.pending, (state) => {
                state.status = 'loading';
                if (!state.statusFirstLoad) {
                    state.statusFirstLoad = 'loading';
                }
            })
            .addCase(getFullContact.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.needLogin = action.payload.need_login;
                state.fullContact = action.payload[0];
            })
            .addCase(getFullContact.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.statusFirstLoad = 'failed';
            })

            .addCase(updateContact.pending, (state) => {
                state.status = 'loading';
                if (!state.statusFirstLoad) {
                    state.statusFirstLoad = 'loading';
                }
            })
            .addCase(updateContact.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.needLogin = action.payload.need_login;
                const contactIndex = state.contacts.findIndex(contact => contact.id === action.payload.resourceName);
                if (contactIndex !== -1) {
                    const { displayName } = action.payload.name;
                    const email = action.payload.emailAddresses?.[0].value;
                    const phone = action.payload.phoneNumbers?.[0].value;
                    state.contacts[contactIndex] = {
                        ...state.contacts[contactIndex],
                        name: displayName,
                        email,
                        phone
                    };
                }
            })
            .addCase(updateContact.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.statusFirstLoad = 'failed';
            })

            .addCase(deleteContacts.pending, (state) => {
                state.deleteContactsStatus = 'loading';
                if (!state.statusFirstLoad) {
                    state.statusFirstLoad = 'loading';
                }
            })
            .addCase(deleteContacts.fulfilled, (state, action) => {
                state.deleteContactsStatus = 'succeeded';
                state.needLogin = action.payload.need_login;
                const deletedContactsSet = new Set(action.meta.arg);
                state.contacts = state.contacts.filter(contact => !deletedContactsSet.has(contact.id));
            })
            .addCase(deleteContacts.rejected, (state, action) => {
                state.deleteContactsStatus = 'failed';
                state.error = action.error.message;
                state.statusFirstLoad = 'failed';
            })
    },
})

export const selectContacts = (state) => state.contacts.contacts;

export const {
    updateContacts,
    setRowSelectionModel,
    setRowHover,
    addContactsToGroup,
    deleteContactsFromGroup,
    setContactsToDelete,
    updateStatusFirstLoad,
    setSelectedContacts,
    addNewContactToStore,
} = dataSlice.actions;

export default dataSlice.reducer
