import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const AvatarsSharedBy = [...Array(120)].map((_, index) => ({
    id: faker.datatype.uuid(),
    name: faker.name.fullName(),
    email: faker.internet.email(),
    photo: sample([
        'https://lh3.googleusercontent.com/a/ACg8ocLx9A8VmUUwsJabsTteOWJ0coXrJoI1SeLkcgNiUl0Ai3NPQsMv2Q=s100',
        'https://lh3.googleusercontent.com/cm/AGPWSu9ZqBCPxlUwe0PstrBwmt4hJkQ68tXUj7bVsHcgY04pmQzxgXjp6KOYlWwQTU_90WG27A=s100',
        'https://lh3.googleusercontent.com/cm/AGPWSu-8XkPqdJ4aGCI61A764khkkbh4CSomeqqUGWGrfqiMBXEASz4lJNKoPqU3zhTP_XpJbA=s100',
        null,
    ]),
    shareType: sample(['USER', 'GROUP']),
}));

export default AvatarsSharedBy;
