// CustomLoadingOverlay.jsx
import React, { useEffect, useState } from "react";
import { Box, Skeleton, Stack } from "@mui/material";
import { GridOverlay } from "@mui/x-data-grid";

const CustomLoadingOverlay = React.memo(({ topOffset, rowHeight }) => {
    const [numRows, setNumRows] = useState(0);
    useEffect(() => {
        const calculateRows = () => {
            const windowHeight = window.innerHeight;
            const availableHeight = windowHeight - topOffset;
            const rows = Math.max(Math.floor(availableHeight / rowHeight), 1);
            setNumRows(rows);
        };
        calculateRows();
        window.addEventListener('resize', calculateRows);
        return () => window.removeEventListener('resize', calculateRows);
    }, [topOffset]);
    const OneRowContact = React.memo(() => (
        <Stack spacing={2} direction={'row'}>
            <Skeleton variant="rounded" height={40} width={40} />
            <Skeleton variant="rounded" height={40} width={'100%'} />
        </Stack>
    ));
    return (
        <GridOverlay>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    zIndex: 1,
                    paddingTop: 2,
                }}
            >
                <Stack spacing={4}>
                    {[...Array(numRows).keys()].map((_, index) => <OneRowContact key={index} />)}
                </Stack>
            </Box>
        </GridOverlay>
    );
});

export default CustomLoadingOverlay;
