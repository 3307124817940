import * as React from "react";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Box, Divider} from "@mui/material";

// project import
import {SkeletonAllLabels, SkeletonLabels, SkeletonLabelsPanel} from "../Skeletons";
import {Labels} from "./Labels";
import {AllContactsLabel} from "./AllContacts";
import {LabelsPanel} from "./LabelsPanel";
import SubscriptionStatusAlert from "../Alerts/SubscriptionStatusAlert";

export default function SidebarLabels() {
    const statusFirstLoad = useSelector((state) => state.contacts.statusFirstLoad);
    const [ref, setRef] = useState();
    const [margin, setMargin] = useState(0);
    const loading = statusFirstLoad === 'loading';
    useEffect(() => {
        if (ref) {
            setMargin(ref.clientHeight);
        }
    }, [ref]);
    return (
        <>
            <Box style={{overflowY: "auto", marginBottom: margin}}>
                {loading ? <SkeletonAllLabels/> : <AllContactsLabel/>}
                <Divider/>
                {loading ? <SkeletonLabelsPanel/> : <LabelsPanel/>}
                {loading ? <SkeletonLabels/> : <Labels/>}
            </Box>
            <SubscriptionStatusAlert setRef={setRef}/>
        </>
    )
}
