import * as React from "react";
import {Alert, Box, Typography} from "@mui/material";
import {ALERT_HEIGHT, HEADER_HEIGHT} from "../../constants/size";

// project import

export default function NoLicense() {
    return (
        <Box
            sx={{
                position: 'fixed',
                top: `${HEADER_HEIGHT}px`,
                left: 0,
                width: '100%',
                height: `${ALERT_HEIGHT}px`,
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
        >
            <Alert
                sx={{height: '100', background: '#FDF0E6'}}
                severity="warning"
            >
                <Message/>
            </Alert>
        </Box>
    )
}

const Message = () => {
    return (
        <Typography sx={typographyStyles}>
            It looks like you don’t have a license yet. Please ask your subscription manager at{' '}
            <Box component="span" sx={{fontWeight: 600}}>
                admin@yourdomain.com
            </Box>
            {' '}to assign a license.
        </Typography>
    );
}

// Styles
const typographyStyles = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "0.17px",
    color: "#5F2B01",
};
